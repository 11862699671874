@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)
      fixed center center;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 30px;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 30px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 30px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 30px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 30px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 30px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: 2px solid currentcolor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control-plaintext::-moz-placeholder,
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext::placeholder,
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control-plaintext:-webkit-autofill,
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select,
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select,
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(
    .valid-tooltip
  ):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #198754;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-control-color.is-valid,
.was-validated .form-control-color:valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #198754;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #198754;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #dc3545;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-control-color.is-invalid,
.was-validated .form-control-color:invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #dc3545;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:active + .btn:focus,
.btn-check:checked + .btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}
.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}
.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}
.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-group-lg > .btn,
.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}
.btn-group-sm > .btn,
.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.375rem;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: 0 0;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}
.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.disabled > .page-link,
.page-link.disabled {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}
.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}
.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}
.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}
.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}
.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}
.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}
.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}
.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}
.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}
.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}
.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}
.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}
.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}
.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}
.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  position: absolute;
  z-index: 1090;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}
.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5)
    calc(var(--bs-modal-header-padding-x) * -0.5)
    calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-footer,
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-footer,
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-footer,
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-footer,
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-footer,
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-top > .popover-arrow::before {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-end > .popover-arrow::before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::before {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-start > .popover-arrow::before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
.spinner-border,
.spinner-grow {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  -webkit-animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.hiding,
  .offcanvas-sm.show,
  .offcanvas-sm.showing {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}
@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}
.link-primary {
  color: #0d6efd !important;
}
.link-primary:focus,
.link-primary:hover {
  color: #0a58ca !important;
}
.link-secondary {
  color: #6c757d !important;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #565e64 !important;
}
.link-success {
  color: #198754 !important;
}
.link-success:focus,
.link-success:hover {
  color: #146c43 !important;
}
.link-info {
  color: #0dcaf0 !important;
}
.link-info:focus,
.link-info:hover {
  color: #3dd5f3 !important;
}
.link-warning {
  color: #ffc107 !important;
}
.link-warning:focus,
.link-warning:hover {
  color: #ffcd39 !important;
}
.link-danger {
  color: #dc3545 !important;
}
.link-danger:focus,
.link-danger:hover {
  color: #b02a37 !important;
}
.link-light {
  color: #f8f9fa !important;
}
.link-light:focus,
.link-light:hover {
  color: #f9fafb !important;
}
.link-dark {
  color: #212529 !important;
}
.link-dark:focus,
.link-dark:hover {
  color: #1a1e21 !important;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-1 {
  --bs-border-width: 1px;
}
.border-2 {
  --bs-border-width: 2px;
}
.border-3 {
  --bs-border-width: 3px;
}
.border-4 {
  --bs-border-width: 4px;
}
.border-5 {
  --bs-border-width: 5px;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */

@font-face {
  font-family: "icon-dukamarket";
  src: url("fonts/icon-dukamarket.eot");
  src: url("fonts/icon-dukamarket.eot") format("embedded-opentype"),
    url("fonts/icon-dukamarket.ttf") format("truetype"),
    url("fonts/icon-dukamarket.woff") format("woff"),
    url("fonts/icon-dukamarket.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-dukamarket" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-earphones:before {
  content: "\ea59";
}
.icon-shipping:before {
  content: "\e916";
}
.icon-friendly-services:before {
  content: "\e914";
}
.icon-help-center:before {
  content: "\e91b";
}
.icon-wishlist:before {
  content: "\e910";
}
.icon-call:before {
  content: "\e911";
}
.icon-confidence:before {
  content: "\e912";
}
.icon-payment:before {
  content: "\e915";
}
.icon-cart:before {
  content: "\e917";
}
.icon-home1:before {
  content: "\e918";
}
.icon-menu:before {
  content: "\e919";
}
.icon-user:before {
  content: "\e91a";
}
.icon-error_outline:before {
  content: "\e900";
}
.icon-pause_circle_outline:before {
  content: "\e901";
}
.icon-play_circle_outline:before {
  content: "\e902";
}
.icon-crop_free:before {
  content: "\e903";
}
.icon-tune:before {
  content: "\e904";
}
.icon-local_grocery_store:before {
  content: "\e905";
}
.icon-fullscreen:before {
  content: "\e906";
}
.icon-fullscreen_exit:before {
  content: "\e907";
}
.icon-thumb_down_alt:before {
  content: "\e908";
}
.icon-thumb_up_alt:before {
  content: "\e909";
}
.icon-star:before {
  content: "\e90a";
}
.icon-star_half:before {
  content: "\e90b";
}
.icon-star_outline:before {
  content: "\e90c";
}
.icon-account_circle:before {
  content: "\e90d";
}
.icon-add_shopping_cart:before {
  content: "\e90e";
}
.icon-favorite:before {
  content: "\e90f";
}
.icon-favorite_outline:before {
  content: "\e913";
}
.icon-launch:before {
  content: "\e91c";
}
.icon-stars:before {
  content: "\e91d";
}
.icon-delete_outline:before {
  content: "\e91e";
}
.icon-star_outline1:before {
  content: "\e91f";
}
.icon-star_rate:before {
  content: "\e920";
}
.icon-activity:before {
  content: "\e921";
}
.icon-airplay:before {
  content: "\e922";
}
.icon-alert-circle:before {
  content: "\e923";
}
.icon-alert-octagon:before {
  content: "\e924";
}
.icon-alert-triangle:before {
  content: "\e925";
}
.icon-align-center:before {
  content: "\e926";
}
.icon-align-justify:before {
  content: "\e927";
}
.icon-align-left:before {
  content: "\e928";
}
.icon-align-right:before {
  content: "\e929";
}
.icon-anchor:before {
  content: "\e92a";
}
.icon-aperture:before {
  content: "\e92b";
}
.icon-archive:before {
  content: "\e92c";
}
.icon-arrow-down:before {
  content: "\e92d";
}
.icon-arrow-down-circle:before {
  content: "\e92e";
}
.icon-arrow-down-left:before {
  content: "\e92f";
}
.icon-arrow-down-right:before {
  content: "\e930";
}
.icon-arrow-left:before {
  content: "\e931";
}
.icon-arrow-left-circle:before {
  content: "\e932";
}
.icon-arrow-right:before {
  content: "\e933";
}
.icon-arrow-right-circle:before {
  content: "\e934";
}
.icon-arrow-up:before {
  content: "\e935";
}
.icon-arrow-up-circle:before {
  content: "\e936";
}
.icon-arrow-up-left:before {
  content: "\e937";
}
.icon-arrow-up-right:before {
  content: "\e938";
}
.icon-at-sign:before {
  content: "\e939";
}
.icon-award:before {
  content: "\e93a";
}
.icon-bar-chart:before {
  content: "\e93b";
}
.icon-bar-chart-2:before {
  content: "\e93c";
}
.icon-battery:before {
  content: "\e93d";
}
.icon-battery-charging:before {
  content: "\e93e";
}
.icon-bell:before {
  content: "\e93f";
}
.icon-bell-off:before {
  content: "\e940";
}
.icon-bluetooth:before {
  content: "\e941";
}
.icon-bold:before {
  content: "\e942";
}
.icon-book:before {
  content: "\e943";
}
.icon-book-open:before {
  content: "\e944";
}
.icon-bookmark:before {
  content: "\e945";
}
.icon-box:before {
  content: "\e946";
}
.icon-briefcase:before {
  content: "\e947";
}
.icon-calendar:before {
  content: "\e948";
}
.icon-camera:before {
  content: "\e949";
}
.icon-camera-off:before {
  content: "\e94a";
}
.icon-cast:before {
  content: "\e94b";
}
.icon-check:before {
  content: "\e94c";
}
.icon-check-circle:before {
  content: "\e94d";
}
.icon-check-square:before {
  content: "\e94e";
}
.icon-chevron-down:before {
  content: "\e94f";
}
.icon-chevron-left:before {
  content: "\e950";
}
.icon-chevron-right:before {
  content: "\e951";
}
.icon-chevron-up:before {
  content: "\e952";
}
.icon-chevrons-down:before {
  content: "\e953";
}
.icon-chevrons-left:before {
  content: "\e954";
}
.icon-chevrons-right:before {
  content: "\e955";
}
.icon-chevrons-up:before {
  content: "\e956";
}
.icon-chrome:before {
  content: "\e957";
}
.icon-circle:before {
  content: "\e958";
}
.icon-clipboard:before {
  content: "\e959";
}
.icon-clock:before {
  content: "\e95a";
}
.icon-cloud:before {
  content: "\e95b";
}
.icon-cloud-drizzle:before {
  content: "\e95c";
}
.icon-cloud-lightning:before {
  content: "\e95d";
}
.icon-cloud-off:before {
  content: "\e95e";
}
.icon-cloud-rain:before {
  content: "\e95f";
}
.icon-cloud-snow:before {
  content: "\e960";
}
.icon-code:before {
  content: "\e961";
}
.icon-codepen:before {
  content: "\e962";
}
.icon-codesandbox:before {
  content: "\e963";
}
.icon-coffee:before {
  content: "\e964";
}
.icon-columns:before {
  content: "\e965";
}
.icon-command:before {
  content: "\e966";
}
.icon-compass:before {
  content: "\e967";
}
.icon-copy:before {
  content: "\e968";
}
.icon-corner-down-left:before {
  content: "\e969";
}
.icon-corner-down-right:before {
  content: "\e96a";
}
.icon-corner-left-down:before {
  content: "\e96b";
}
.icon-corner-left-up:before {
  content: "\e96c";
}
.icon-corner-right-down:before {
  content: "\e96d";
}
.icon-corner-right-up:before {
  content: "\e96e";
}
.icon-corner-up-left:before {
  content: "\e96f";
}
.icon-corner-up-right:before {
  content: "\e970";
}
.icon-cpu:before {
  content: "\e971";
}
.icon-credit-card:before {
  content: "\e972";
}
.icon-crop:before {
  content: "\e973";
}
.icon-crosshair:before {
  content: "\e974";
}
.icon-database:before {
  content: "\e975";
}
.icon-delete:before {
  content: "\e976";
}
.icon-disc:before {
  content: "\e977";
}
.icon-dollar-sign:before {
  content: "\e978";
}
.icon-download:before {
  content: "\e979";
}
.icon-download-cloud:before {
  content: "\e97a";
}
.icon-droplet:before {
  content: "\e97b";
}
.icon-edit:before {
  content: "\e97c";
}
.icon-edit-2:before {
  content: "\e97d";
}
.icon-edit-3:before {
  content: "\e97e";
}
.icon-external-link:before {
  content: "\e97f";
}
.icon-eye:before {
  content: "\e980";
}
.icon-eye-off:before {
  content: "\e981";
}
.icon-facebook:before {
  content: "\e982";
}
.icon-fast-forward:before {
  content: "\e983";
}
.icon-feather:before {
  content: "\e984";
}
.icon-figma:before {
  content: "\e985";
}
.icon-file:before {
  content: "\e986";
}
.icon-file-minus:before {
  content: "\e987";
}
.icon-file-plus:before {
  content: "\e988";
}
.icon-file-text:before {
  content: "\e989";
}
.icon-film:before {
  content: "\e98a";
}
.icon-filter:before {
  content: "\e98b";
}
.icon-flag:before {
  content: "\e98c";
}
.icon-folder:before {
  content: "\e98d";
}
.icon-folder-minus:before {
  content: "\e98e";
}
.icon-folder-plus:before {
  content: "\e98f";
}
.icon-framer:before {
  content: "\e990";
}
.icon-frown:before {
  content: "\e991";
}
.icon-gift:before {
  content: "\e992";
}
.icon-git-branch:before {
  content: "\e993";
}
.icon-git-commit:before {
  content: "\e994";
}
.icon-git-merge:before {
  content: "\e995";
}
.icon-git-pull-request:before {
  content: "\e996";
}
.icon-github:before {
  content: "\e997";
}
.icon-gitlab:before {
  content: "\e998";
}
.icon-globe:before {
  content: "\e999";
}
.icon-grid:before {
  content: "\e99a";
}
.icon-hard-drive:before {
  content: "\e99b";
}
.icon-hash:before {
  content: "\e99c";
}
.icon-headphones:before {
  content: "\e99d";
}
.icon-heart:before {
  content: "\e99e";
}
.icon-help-circle:before {
  content: "\e99f";
}
.icon-hexagon:before {
  content: "\e9a0";
}
.icon-home:before {
  content: "\e9a1";
}
.icon-image:before {
  content: "\e9a2";
}
.icon-inbox:before {
  content: "\e9a3";
}
.icon-info:before {
  content: "\e9a4";
}
.icon-instagram:before {
  content: "\e9a5";
}
.icon-italic:before {
  content: "\e9a6";
}
.icon-key:before {
  content: "\e9a7";
}
.icon-layers:before {
  content: "\e9a8";
}
.icon-layout:before {
  content: "\e9a9";
}
.icon-life-buoy:before {
  content: "\e9aa";
}
.icon-link:before {
  content: "\e9ab";
}
.icon-link-2:before {
  content: "\e9ac";
}
.icon-linkedin:before {
  content: "\e9ad";
}
.icon-list:before {
  content: "\e9ae";
}
.icon-loader:before {
  content: "\e9af";
}
.icon-lock:before {
  content: "\e9b0";
}
.icon-log-in:before {
  content: "\e9b1";
}
.icon-log-out:before {
  content: "\e9b2";
}
.icon-mail:before {
  content: "\e9b3";
}
.icon-map:before {
  content: "\e9b4";
}
.icon-map-pin:before {
  content: "\e9b5";
}
.icon-maximize:before {
  content: "\e9b6";
}
.icon-maximize-2:before {
  content: "\e9b7";
}
.icon-meh:before {
  content: "\e9b8";
}
.icon-menu1:before {
  content: "\e9b9";
}
.icon-message-circle:before {
  content: "\e9ba";
}
.icon-message-square:before {
  content: "\e9bb";
}
.icon-mic:before {
  content: "\e9bc";
}
.icon-mic-off:before {
  content: "\e9bd";
}
.icon-minimize:before {
  content: "\e9be";
}
.icon-minimize-2:before {
  content: "\e9bf";
}
.icon-minus:before {
  content: "\e9c0";
}
.icon-minus-circle:before {
  content: "\e9c1";
}
.icon-minus-square:before {
  content: "\e9c2";
}
.icon-monitor:before {
  content: "\e9c3";
}
.icon-moon:before {
  content: "\e9c4";
}
.icon-more-horizontal:before {
  content: "\e9c5";
}
.icon-more-vertical:before {
  content: "\e9c6";
}
.icon-mouse-pointer:before {
  content: "\e9c7";
}
.icon-move:before {
  content: "\e9c8";
}
.icon-music:before {
  content: "\e9c9";
}
.icon-navigation:before {
  content: "\e9ca";
}
.icon-navigation-2:before {
  content: "\e9cb";
}
.icon-octagon:before {
  content: "\e9cc";
}
.icon-package:before {
  content: "\e9cd";
}
.icon-paperclip:before {
  content: "\e9ce";
}
.icon-pause:before {
  content: "\e9cf";
}
.icon-pause-circle:before {
  content: "\e9d0";
}
.icon-pen-tool:before {
  content: "\e9d1";
}
.icon-percent:before {
  content: "\e9d2";
}
.icon-phone:before {
  content: "\e9d3";
}
.icon-phone-call:before {
  content: "\e9d4";
}
.icon-phone-forwarded:before {
  content: "\e9d5";
}
.icon-phone-incoming:before {
  content: "\e9d6";
}
.icon-phone-missed:before {
  content: "\e9d7";
}
.icon-phone-off:before {
  content: "\e9d8";
}
.icon-phone-outgoing:before {
  content: "\e9d9";
}
.icon-pie-chart:before {
  content: "\e9da";
}
.icon-play:before {
  content: "\e9db";
}
.icon-play-circle:before {
  content: "\e9dc";
}
.icon-plus:before {
  content: "\e9dd";
}
.icon-plus-circle:before {
  content: "\e9de";
}
.icon-plus-square:before {
  content: "\e9df";
}
.icon-pocket:before {
  content: "\e9e0";
}
.icon-power:before {
  content: "\e9e1";
}
.icon-printer:before {
  content: "\e9e2";
}
.icon-radio:before {
  content: "\e9e3";
}
.icon-refresh-ccw:before {
  content: "\e9e4";
}
.icon-refresh-cw:before {
  content: "\e9e5";
}
.icon-repeat:before {
  content: "\e9e6";
}
.icon-rewind:before {
  content: "\e9e7";
}
.icon-rotate-ccw:before {
  content: "\e9e8";
}
.icon-rotate-cw:before {
  content: "\e9e9";
}
.icon-rss:before {
  content: "\e9ea";
}
.icon-save:before {
  content: "\e9eb";
}
.icon-scissors:before {
  content: "\e9ec";
}
.icon-search:before {
  content: "\e9ed";
}
.icon-send:before {
  content: "\e9ee";
}
.icon-server:before {
  content: "\e9ef";
}
.icon-settings:before {
  content: "\e9f0";
}
.icon-share:before {
  content: "\e9f1";
}
.icon-share-2:before {
  content: "\e9f2";
}
.icon-shield:before {
  content: "\e9f3";
}
.icon-shield-off:before {
  content: "\e9f4";
}
.icon-shopping-bag:before {
  content: "\e9f5";
}
.icon-shopping-cart:before {
  content: "\e9f6";
}
.icon-shuffle:before {
  content: "\e9f7";
}
.icon-sidebar:before {
  content: "\e9f8";
}
.icon-skip-back:before {
  content: "\e9f9";
}
.icon-skip-forward:before {
  content: "\e9fa";
}
.icon-slack:before {
  content: "\e9fb";
}
.icon-slash:before {
  content: "\e9fc";
}
.icon-sliders:before {
  content: "\e9fd";
}
.icon-smartphone:before {
  content: "\e9fe";
}
.icon-smile:before {
  content: "\e9ff";
}
.icon-speaker:before {
  content: "\ea00";
}
.icon-square:before {
  content: "\ea01";
}
.icon-star1:before {
  content: "\ea02";
}
.icon-stop-circle:before {
  content: "\ea03";
}
.icon-sun:before {
  content: "\ea04";
}
.icon-sunrise:before {
  content: "\ea05";
}
.icon-sunset:before {
  content: "\ea06";
}
.icon-tablet:before {
  content: "\ea07";
}
.icon-tag:before {
  content: "\ea08";
}
.icon-target:before {
  content: "\ea09";
}
.icon-terminal:before {
  content: "\ea0a";
}
.icon-thermometer:before {
  content: "\ea0b";
}
.icon-thumbs-down:before {
  content: "\ea0c";
}
.icon-thumbs-up:before {
  content: "\ea0d";
}
.icon-toggle-left:before {
  content: "\ea0e";
}
.icon-toggle-right:before {
  content: "\ea0f";
}
.icon-tool:before {
  content: "\ea10";
}
.icon-trash:before {
  content: "\ea11";
}
.icon-trash-2:before {
  content: "\ea12";
}
.icon-trello:before {
  content: "\ea13";
}
.icon-trending-down:before {
  content: "\ea14";
}
.icon-trending-up:before {
  content: "\ea15";
}
.icon-triangle:before {
  content: "\ea16";
}
.icon-truck:before {
  content: "\ea17";
}
.icon-tv:before {
  content: "\ea18";
}
.icon-twitch:before {
  content: "\ea19";
}
.icon-twitter:before {
  content: "\ea1a";
}
.icon-type:before {
  content: "\ea1b";
}
.icon-umbrella:before {
  content: "\ea1c";
}
.icon-underline:before {
  content: "\ea1d";
}
.icon-unlock:before {
  content: "\ea1e";
}
.icon-upload:before {
  content: "\ea1f";
}
.icon-upload-cloud:before {
  content: "\ea20";
}
.icon-user1:before {
  content: "\ea21";
}
.icon-user-check:before {
  content: "\ea22";
}
.icon-user-minus:before {
  content: "\ea23";
}
.icon-user-plus:before {
  content: "\ea24";
}
.icon-user-x:before {
  content: "\ea25";
}
.icon-users:before {
  content: "\ea26";
}
.icon-video:before {
  content: "\ea27";
}
.icon-video-off:before {
  content: "\ea28";
}
.icon-voicemail:before {
  content: "\ea29";
}
.icon-volume:before {
  content: "\ea2a";
}
.icon-volume-1:before {
  content: "\ea2b";
}
.icon-volume-2:before {
  content: "\ea2c";
}
.icon-volume-x:before {
  content: "\ea2d";
}
.icon-watch:before {
  content: "\ea2e";
}
.icon-wifi:before {
  content: "\ea2f";
}
.icon-wifi-off:before {
  content: "\ea30";
}
.icon-wind:before {
  content: "\ea31";
}
.icon-x:before {
  content: "\ea32";
}
.icon-x-circle:before {
  content: "\ea33";
}
.icon-x-octagon:before {
  content: "\ea34";
}
.icon-x-square:before {
  content: "\ea35";
}
.icon-youtube:before {
  content: "\ea36";
}
.icon-zap:before {
  content: "\ea37";
}
.icon-zap-off:before {
  content: "\ea38";
}
.icon-zoom-in:before {
  content: "\ea39";
}
.icon-zoom-out:before {
  content: "\ea3a";
}
.icon-heart-outlined:before {
  content: "\ea3b";
}
.icon-heart1:before {
  content: "\ea3c";
}
.icon-message:before {
  content: "\ea3d";
}
.icon-plus1:before {
  content: "\ea3e";
}
.icon-reply:before {
  content: "\ea3f";
}
.icon-shield1:before {
  content: "\ea40";
}
.icon-shopping-bag1:before {
  content: "\ea41";
}
.icon-shopping-basket:before {
  content: "\ea42";
}
.icon-sound-mix:before {
  content: "\ea43";
}
.icon-text:before {
  content: "\ea44";
}
.icon-typing:before {
  content: "\ea45";
}
.icon-500px-with-circle:before {
  content: "\ea46";
}
.icon-skype-with-circle:before {
  content: "\ea47";
}
.icon-spotify-with-circle:before {
  content: "\ea48";
}
.icon-spotify:before {
  content: "\ea49";
}
.icon-vine-with-circle:before {
  content: "\ea4a";
}
.icon-vk-with-circle:before {
  content: "\ea4b";
}
.icon-xing-with-circle:before {
  content: "\ea4c";
}
.icon-facebook-with-circle:before {
  content: "\ea4d";
}
.icon-github-with-circle:before {
  content: "\ea4e";
}
.icon-google-with-circle:before {
  content: "\ea4f";
}
.icon-instagram-with-circle:before {
  content: "\ea50";
}
.icon-lastfm-with-circle:before {
  content: "\ea51";
}
.icon-linkedin-with-circle:before {
  content: "\ea52";
}
.icon-pinterest-with-circle:before {
  content: "\ea53";
}
.icon-stumbleupon-with-circle:before {
  content: "\ea54";
}
.icon-tumblr-with-circle:before {
  content: "\ea55";
}
.icon-twitter-with-circle:before {
  content: "\ea56";
}
.icon-vimeo-with-circle:before {
  content: "\ea57";
}
.icon-youtube-with-circle:before {
  content: "\ea58";
}

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&family=Schoolbell&display=swap");
:root {
  /**
  @font family declaration
  */
  --tp-ff-body: "Jost", sans-serif;
  --tp-ff-heading: "Quicksand", sans-serif;
  --tp-ff-p: "Jost", sans-serif;
  --tp-ff-jost: "Jost", sans-serif;
  --tp-ff-font-family: Font Awesome 5 Pro;
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-black: #000;
  --tp-heading-primary: #2d2a6e;
  --tp-heading-secondary: #96ae00;
  --tp-grey-1: #f2f2f6;
  --tp-grey-2: #f5f6f8;
  --tp-grey-3: #f5f2ff;
  --tp-grey-4: #fff3ec;
  --tp-grey-5: #ecfbff;
  --tp-grey-6: #feefd0;
  --tp-grey-7: #c4c4c4;
  --tp-grey-8: #f7f7f9;
  --tp-grey-9: #b4dea4;
  --tp-grey-10: #b0afcc;
  --tp-text-body: #4d5574;
  --tp-text-1: #ffb800;
  --tp-text-2: #334763;
  --tp-text-3: #79819c;
  --tp-text-4: #facfd4;
  --tp-text-5: #ffd600;
  --tp-theme-1: #2d2a6e;
  --tp-theme-2: #ea0d42;
  --tp-theme-3: #12b7ce;
  --tp-theme-4: #2d2c6e;
  --tp-theme-5: #d91212;
  --tp-border-1: #e6ebf0;
  --tp-border-2: #acafb7;
  --tp-border-3: #ebeff4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-body);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  line-height: 23px;
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-heading);
  color: #2d2a6e;
  margin-top: 0px;
  font-weight: var(--tp-fw-sbold);
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: var(--tp-ff-p);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
  line-height: 23px;
}

a,
.btn,
button,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

input::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #79819c;
}

.custom-container {
  max-width: 1200px;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
.w-img img {
  margin: 0 auto;
  max-height: 400px;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-row {
  display: flex;
  justify-content: space-between;
}

.gx-6 {
  --bs-gutter-x: 40px;
}

/* scroll up */
.scroll-top {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  z-index: 99;
  background-color: var(--tp-heading-secondary);
  opacity: 0.5;
  font-size: 16px;
  color: var(--tp-common-white);
  cursor: pointer;

  border: none;
  bottom: -10%;
  position: fixed;
  right: 50px;
}
.scroll-top:hover {
  opacity: 1;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}

.scroll-top.open {
  bottom: 30px;
}

.gx-7 {
  --bs-gutter-x: 20px;
}

/*--
    - Spacing
-----------------------------------------*/
.tp-btn {
  background-color: var(--tp-heading-secondary);
  text-align: center;
  color: var(--tp-common-white);
  font-weight: 600;
  border-radius: 50px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  padding: 13px 53px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-btn {
    padding: 8px 30px;
  }
}
.tp-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-heading-primary);
}

.tp-btn-2 {
  padding: 1px 40px;
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  font-weight: 600;
  border-radius: 50px;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-btn-2 {
    padding: 1px 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-btn-2 {
    padding: 1px 30px;
  }
}
.tp-btn-2:hover {
  color: var(--tp-common-white);
  background-color: #859a00;
}

.tp-btn-3,
.tp-btn-4,
.whight-btn {
  padding: 5px 55px;
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  font-weight: 600;
  border-radius: 50px;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-btn-3,
  .tp-btn-4,
  .whight-btn {
    padding: 5px 40px;
  }
}
.tp-btn-3:hover,
.tp-btn-4:hover,
.whight-btn:hover {
  color: var(--tp-common-white);
  background-color: #859a00;
}

.tp-btn-4 {
  background-color: var(--tp-heading-primary);
}
.tp-btn-4:hover {
  color: var(--tp-common-white);
  pointer-events: auto;
  background-color: #859a00;
}

.whight-btn {
  color: var(--tp-heading-secondary);
  background-color: var(--tp-common-white);
  border: 2px solid var(--tp-common-white);
}
.whight-btn:hover {
  color: var(--tp-common-white);
  background-color: transparent;
  border: 2px solid var(--tp-common-white);
}

.tp-news-btn {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: var(--tp-common-white);
  text-transform: uppercase;
  padding: 12px 30px;
  background-color: var(--tp-heading-secondary);
  border-radius: 3px;
}

.tpcart-btn,
.tpcheck-btn {
  border: 2px solid var(--tp-heading-secondary);
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  min-height: 45px;
  text-transform: uppercase;
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  border-radius: 30px;
  padding: 10px 30px;
  text-align: center;
  line-height: 1.5;
  padding: 14px;
  font-weight: 600;
}
.tpcart-btn:hover,
.tpcheck-btn:hover {
  background-color: #859a00;
  color: var(--tp-common-white);
  border: 2px solid #859a00;
}

.tpcart-btn {
  background-color: transparent;
  color: var(--tp-heading-secondary);
}
.tpcart-btn:hover {
  background-color: #859a00;
  color: var(--tp-common-white);
  border: 2px solid #859a00;
}

.banner-btn {
  background-color: var(--tp-common-white);
  color: var(--tp-heading-primary);
}

.border-btn {
  background-color: transparent;
  color: var(--tp-common-white);
}
.border-btn:hover {
  background-color: var(--tp-common-white);
  color: var(--tp-heading-secondary);
}

/* 01. ========= shape animation ========== */
@keyframes lara {
  0% {
    transform: translateY(-1px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(-7px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(-1px);
  }
}
@keyframes chara {
  100% {
    transform: translateY(-8px);
  }
}
/* 01. ========= animation plus ========== */
@keyframes animation-plus {
  25% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}
/* 01. ========= animation plus ========== */
@keyframes shakenext {
  25% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-3px, 0, 0);
  }
  75% {
    transform: translate3d(3px, 0, 0);
  }
}
/* 01. ========= shape animation ========== */
@keyframes slider-anim {
  0% {
    transform: translateX(-1px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(-1px);
  }
}
/* 01. ========= up-down animation ========== */
@keyframes up-down {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* 01. ========= up-down animation ========== */
@keyframes left-right {
  0% {
    transform: translateX(3px);
  }
  25% {
    transform: translateX(7px);
  }
  50% {
    transform: translateX(15px);
  }
  75% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(2px);
  }
}
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: var(--tp-grey-1);
}

.grey-bg-2 {
  background: var(--tp-grey-2);
}

.white-bg {
  background: var(--tp-common-white);
}

.black-bg {
  background: var(--tp-common-black);
}

.theme-bg-1 {
  background-color: var(--tp-theme-1);
}

.theme-bg-2 {
  background-color: var(--tp-theme-4);
}

.tp-breadcrumb__title {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--tp-heading-primary);
  letter-spacing: -0.03em;
  margin-bottom: 0;
}
.tp-breadcrumb__content {
  padding: 8px 0;
}
.tp-breadcrumb__list span {
  font-size: 14px;
  color: var(--tp-theme-1);
  line-height: 30px;
  font-weight: 400;
}
.tp-breadcrumb__list .dvdr {
  color: var(--tp-text-3);
  transform: translateY(0px);
  display: inline-block;
  padding: 0 0;
}
.tp-breadcrumb__active {
  color: var(--tp-text-3) !important;
}
.tp-breadcrumb__active a:hover {
  color: var(--tp-heading-secondary);
}

.basic-pagination ul li {
  display: inline-block;
  list-style: none;
}
.basic-pagination ul li:not(:last-child) {
  margin-right: 5px;
}
.basic-pagination ul li a,
.basic-pagination ul li span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #dce1e8;
  font-size: 14px;
  font-weight: 600;
  color: var(--tp-text-3);
 
}
@media (max-width: 767px) {
  .basic-pagination ul li a,
  .basic-pagination ul li span {
    height: 30px;
    width: 30px;
    line-height: 28px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .basic-pagination ul li a,
  .basic-pagination ul li span {
    height: 40px;
    width: 40px;
    line-height: 36px;
    font-size: 14px;
  }
}
.basic-pagination ul li a:hover,
.basic-pagination ul li a.current,
.basic-pagination ul li span:hover,
.basic-pagination ul li span.current {
  background: var(--tp-heading-secondary);
  border-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.basic-pagination ul li a i,
.basic-pagination ul li span i {
  font-weight: 600;
}

.tpnavtab__area nav {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.tpnavtab__area nav .nav-link.active {
  color: var(--tp-heading-secondary);
  background-color: transparent;
  border: none;
}
.tpnavtab__area nav .nav-tabs {
  border-bottom: none;
}
.tpnavtab__area nav .nav-link {
  color: var(--tp-text-body);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  background: 0 0;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0 22px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .tpnavtab__area nav .nav-link {
    padding: 0 8px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpnavtab__area nav .nav-link {
    padding: 0 12px;
    font-size: 14px;
  }
}
.tpnavtab__newitem {
  display: flex;
  align-items: flex-start;
}
.tpnavtab__newitem.tpnavtab__area nav .nav-link.active {
  color: var(--tp-heading-primary);
}
.tpnavtab__newitem.tpnavtab__area nav .nav-link {
  color: var(--tp-text-3);
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0;
  background: 0 0;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0 0;
  margin-right: 28px;
  margin-bottom: 10px;
  font-family: "Quicksand";
}

#nav-tabContent-tp {
  position: relative;
  z-index: 9999;
}

.tpsection p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
}
.tpsection p a {
  color: var(--tp-heading-secondary);
}
.tpsection__sub-title {
  color: var(--tp-heading-secondary);
  font-family: "Schoolbell";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
}
.tpsection__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: var(--tp-heading-primary);
  letter-spacing: -0.3px;
}
@media (max-width: 767px) {
  .tpsection__title {
    font-size: 28px;
  }
}

.section__content p {
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .section__content p br {
    display: none;
  }
}
.section__sub-title {
  font-family: "Schoolbell", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: var(--tp-common-white);
  display: block;
}
.section__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.5px;
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .section__title {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .section__title br {
    display: none;
  }
}

/*----------------------------------------*/
/*  03. MEAN MENU CSS
/*----------------------------------------*/
/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-transform: capitalize !important;
}
.mean-container .mean-nav ul li a:hover {
  color: var(--tp-heading-secondary);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 8px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid #3c3962 !important;
  height: 26px;
  width: 26px;
  line-height: 26px;
  color: #fff;
  top: 0;
  font-weight: 400;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--clr-theme-1);
  color: var(--tp-common-white);
  border-color: var(--clr-theme-1);
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
  font-size: 14px;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--tp-heading-secondary);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  color: var(--tp-heading-secondary);
}

.mean-container .mean-nav ul .home-menu-style li a {
  text-align: center;
}
.mean-container .mean-nav ul li a:hover img {
  transform: scale(0.92);
}
.mean-container .mean-nav ul li a img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 100%;
  margin-bottom: 5px;
}

/*----------------------------------------*/
/*  02. HEADER CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__top-left {
    text-align: center;
  }
}
.header__top-left span {
  color: var(--tp-common-white);
  font-size: 13px;
}
.header__top-left span strong {
  font-weight: 400;
  color: var(--tp-text-1);
  font-size: 13px;
}
.header__top-right {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__top-right {
    justify-content: center;
  }
}
.header__top-link a {
  color: var(--tp-common-white);
  display: inline-block;
  font-size: 13px;
  margin-left: 25px;
}
.header__top-link a:hover {
  color: var(--tp-heading-secondary);
}
.header__top-link a:first-child {
  margin-left: 0;
}
.header__top-price .nice-select {
  background: var(--tp-heading-primary);
  color: #fff;
  border: none;
  font-size: 13px;
  height: 40px;
  width: 60px;
  padding-right: 16px;
  font-weight: 400;
  margin-left: 5px;
}
.header__top-price .nice-select::after {
  height: 6px;
  margin-top: -5px;
  right: 3px;
  width: 6px;
  border-bottom: 1px solid #b0afcc;
  border-right: 1px solid #b0afcc;
}
.header__top-price .nice-select .list {
  background: var(--tp-common-white);
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.1);
  border-radius: 0px 0px 5px 5px !important;
  color: var(--tp-text-2);
  width: 65px;
  padding: 0 0 20px 0;
}
.header__top-price .nice-select .option {
  margin-bottom: -13px;
}
.header__top-price .nice-select .option:hover {
  color: var(--tp-heading-secondary);
}
.header__lang {
  position: relative;
  z-index: 99;
  padding: 7px 0;
}
.header__lang:hover .header__lang-select {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header__lang:hover .header__lang-select i {
  transform: rotate(180deg);
  color: var(--tp-text-primary);
}
.header__lang:hover .header__lang-submenu {
  visibility: visible;
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.header__lang-select {
  color: var(--tp-common-white);
  padding-left: 15px;
  margin-left: 15px;
  position: relative;
}
.header__lang-select::before {
  position: absolute;
  content: "";
  left: 0;
  background-color: #5e5aa3;
  height: 15px;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.header__lang-select i {
  font-size: 14px;
  margin-left: 2px;
  color: #b0afcc;
}
.header__lang-submenu {
  position: absolute;
  top: 110%;
  left: 0px;
  width: 120px;
  background: var(--tp-common-white);
  z-index: 9;
  padding: 14px 16px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.1);
  border-radius: 0px 0px 5px 5px;
}
.header__lang-submenu li {
  list-style: none;
  margin-bottom: 5px;
}
.header__lang-submenu li:last-child {
  margin-bottom: 0;
}
.header__lang-submenu li a {
  font-size: 14px;
  font-weight: 400;
}
.header__lang-submenu li a:hover {
  color: var(--tp-heading-secondary);
}
.header__main-area {
  border: 1px solid #e6eaf0;
}
.main-menu ul li {
  position: relative;
  display: inline-block;
  line-height: 1;
  list-style: none;
}
.main-menu ul li:hover.has-dropdown a::after {
  color: var(--tp-heading-secondary);
}
.main-menu ul li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu ul li a {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  padding: 32px 12px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li a {
    padding: 32px 10px;
  }
}
.main-menu ul li a:hover {
  color: var(--tp-heading-secondary);
}
.main-menu ul li.has-dropdown > a {
  position: relative;
}
.main-menu ul li.has-dropdown > a::after {
  content: "\e94f";
  font-size: 14px;
  color: var(--tp-common-black);
  font-family: "icon-dukamarket";
  font-weight: 400;
  margin-left: 6px;
  display: inline-block;
  font-weight: var(--tp-fw-bold);
}
.main-menu ul li.has-megamenu {
  position: static;
}
.main-menu ul li.has-homemenu {
  position: static;
}
.main-menu ul li .sub-menu {
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 270px;
  padding: 30px 0;
  background-color: var(--tp-common-white);
  z-index: 2;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.1);
  border-radius: 0px 0px 10px 10px;
  border-top: 2px solid var(--tp-heading-secondary);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-menu ul li .sub-menu > li {
  margin: 0;
  list-style: none;
  padding: 0 20px 0 40px;
  display: block;
}
.main-menu ul li .sub-menu > li > a {
  display: block;
  font-family: var(--font-body);
  text-transform: none;
  padding: 10px 0;
  padding-left: 0px;
  color: #334763;
  font-size: var(--menu-font-size);
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  transition: 0.3s;
  line-height: 131%;
  text-align: left;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-menu ul li .sub-menu > li > a:hover {
  color: var(--tp-heading-secondary);
  padding-left: 8px;
}
.main-menu ul li .sub-menu > li > a::before {
  display: none;
}
.main-menu ul li .sub-menu > li > a::after {
  display: none;
}
.main-menu ul li .mega-menu {
  padding: 35px 40px 26px 40px;
  position: absolute;
  top: 120%;
  width: 1200px;
  background-color: var(--tp-common-white);
  left: 0;
  right: 0;
  z-index: 9999;
  transform-origin: top;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.1);
  border-radius: 0px 0px 10px 10px;
  border-top: 2px solid var(--tp-heading-secondary);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu {
    width: 100%;
  }
}
.main-menu ul li .mega-menu li {
  width: 260px;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}
.main-menu ul li .mega-menu li .mega-menu-title {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  position: relative;
  text-transform: uppercase;
  color: var(--tp-heading-secondary);
  font-family: "Jost", sans-serif;
  margin-bottom: 10px;
}
.main-menu ul li .mega-menu li .mega-menu-title:hover {
  padding-left: 0;
}
.main-menu ul li .mega-menu li ul {
  padding-left: 0;
  text-align: left;
}
.main-menu ul li .mega-menu li ul li {
  padding-left: 0;
  text-align: left;
}
.main-menu ul li .mega-menu li ul li a {
  position: relative;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-body);
  text-transform: capitalize;
  text-align: start;
  margin: 0;
  padding-left: 0;
}
.main-menu ul li .mega-menu li ul li a:hover {
  color: var(--tp-heading-secondary);
  padding-left: 8px;
}
.main-menu ul li .home-menu-style {
  width: 100%;
}
.main-menu ul li .home-menu-style li {
  display: inline-block;
  padding: 0 10px;
  width: 230px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .main-menu ul li .home-menu-style li {
    width: 220px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .home-menu-style li {
    width: 175px;
  }
}
.main-menu ul li .home-menu-style li a {
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-menu ul li .home-menu-style li a:hover {
  padding-left: 0px;
  transform: scale(0.92);
}
.main-menu ul li .home-menu-style li a img {
  border: 1px solid rgba(222, 226, 230, 0.5607843137);
  border-radius: 10px;
  width: 100%;
  display: block;
  margin-bottom: 25px;
}

.header__info {
  justify-content: end;
}
.header__info a i,
.header__info button i {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: var(--tp-grey-3);
  text-align: center;
  line-height: 40px;
  color: var(--tp-theme-1);
  display: block;
}
.header__info-cart {
  position: relative;
}
.header__info-cart span {
  position: absolute;
  font-size: 11px;
  background-color: red;
  color: var(--tp-common-white);
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  display: block;
  border-radius: 50px;
  top: 25px;
  right: 5px;
}

.sub-menu.mega-menu {
  background-repeat: no-repeat;
  background-position: right bottom;
}

.tpsearchbar {
  background-color: var(--tp-common-white);
  text-align: start;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 0;
  width: 100%;
  min-height: 320px;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999999;
  padding: 30px;
  transform: translateY(-100%);
}
.tpsearchbar__title {
  font-size: 18px;
  color: var(--tp-theme-1);
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 35px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpsearchbar__title {
    font-size: 12px;
  }
}
.tpsearchbar.tp-searchbar-opened {
  transform: translateY(0);
}
.tpsearchbar__close {
  color: var(--tp-theme-1);
  font-size: 30px;
  background: var(--tp-theme-secondary);
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15%;
  right: 15%;
}
.tpsearchbar__close:hover {
  color: var(--tp-heading-secondary);
}
.tpsearchbar__form {
  position: relative;
}
.tpsearchbar__form input {
  width: 100%;
  height: 45px;
  border: 1px solid var(--tp-border-1);
  padding: 10px 45px 10px 20px;
  color: var(--tp-theme-1);
  font-size: 16px;
  font-weight: 500;
}
.tpsearchbar__form input::placeholder {
  font-size: 13px;
  color: #acafb7;
}
.tpsearchbar__search-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--tp-theme-1);
  font-size: 16px;
}

.search-body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}
.search-body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.tp-cart-toggle {
  font-size: 16px;
  line-height: 0;
}

.tp-cart-info-area.tp-sidebar-opened {
  transform: translateX(0);
}

.tp-sidebar-close {
  color: var(--tp-common-white);
  position: absolute;
  left: -35px;
  font-size: 21px;
  background: #171151;
  width: 35px;
  height: 35px;
}

.tpcartinfo {
  background-color: var(--tp-common-white);
  text-align: start;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999999;
  width: 380px;
  transform: translateX(100%);
}
@media (max-width: 767px) {
  .tpcartinfo {
    width: 280px;
  }
}
.tpcartinfo .tp-shop-sidebar-opened {
  transform: translateX(0);
}

.tpcart__close {
  color: var(--tp-heading-secondary);
  right: 30px;
  font-size: 18px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 8px;
  z-index: 2;
}
@media (max-width: 767px) {
  .tpcart__close {
    width: 30px;
    height: 30px;
  }
}

.cartbody-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}
.cartbody-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.tpcart {
  float: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  flex-direction: column;
}
.tpcart__product {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 30px;
}
.tpcart__title {
  padding: 16px 30px;
  background-color: var(--tp-grey-1);
  font-weight: 700;
  font-size: 14px;
  color: var(--tp-heading-primary);
  text-transform: uppercase;
  margin-bottom: 20px;
}
.tpcart ul li {
  list-style: none;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--tp-border-1);
}
.tpcart__item {
  display: flex;
  align-items: center;
}
.tpcart__img {
  margin-right: 20px;
  position: relative;
}
@media (max-width: 767px) {
  .tpcart__img {
    margin-right: 4px;
  }
}
.tpcart__img img {
  width: 70px;
  border-radius: 10px;
}
.tpcart__del {
  position: absolute;
  color: var(--tp-heading-secondary);
  left: 0;
  top: 0;
}
.tpcart__content-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-heading-primary);
}
.tpcart__content-title a:hover {
  color: var(--tp-heading-secondary);
}
.tpcart__cart-price {
  font-weight: 600;
  font-size: 12px;
  color: var(--tp-heading-primary);
}
.tpcart__cart-price .new-price {
  color: var(--tp-heading-secondary);
}
.tpcart__total-price {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
  margin-bottom: 25px;
  padding-top: 25px;
  border-top: 1px solid var(--tp-border-1);
}
.tpcart__total-price .heilight-price {
  font-size: 18px;
  font-weight: 700;
}
.tpcart__free-shipping {
  padding: 13px 30px;
  background-color: var(--tp-theme-5);
}
@media (max-width: 767px) {
  .tpcart__free-shipping {
    padding: 13px 15px;
  }
}
.tpcart__free-shipping span {
  color: var(--tp-common-white);
  font-size: 14px;
}
.tpcart__free-shipping span b {
  font-weight: 600;
  text-transform: uppercase;
}
.tpcart__checkout {
  margin-bottom: 30px;
}

.tpmobile-menu {
  padding: 20px 0;
  border-bottom: 1px solid var(--tp-border-1);
}

.tp-menu-toggle {
  font-size: 28px;
}

.tp-sidebar-close {
  color: #fff;
  position: absolute;
  left: -35px;
  font-size: 21px;
  background: #171151;
  width: 35px;
  height: 35px;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}
.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-icon {
  font-size: 24px;
  color: var(--tp-heading-primary);
}

.tpsideinfo {
  background: #2d2a6e;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  padding: 30px;
  width: 100%;

  z-index: 999;
  overflow-y: scroll;
}
@media (max-width: 767px) {
  .tpsideinfo {
    width: 100%;
    margin-top: 120px;
  }
}
.tpsideinfo.tp-sidebar-opened {
  transform: translateX(0);
}
.tpsideinfo__close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: var(--tp-common-white);
  width: 100%;
  display: block;
  min-height: 45px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--tp-heading-secondary);
}
.tpsideinfo__search-title {
  color: var(--tp-common-white);
  font-size: 13px;
  text-transform: uppercase;
}
.tpsideinfo__search form {
  position: relative;
  padding-top: 13px;
  padding-bottom: 20px;
}
.tpsideinfo__search form input {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid transparent;
  background: #f3f3f9;
  padding: 10px 20px;
  padding-right: 45px;
}
.tpsideinfo__search form input::placeholder {
  color: var(--tp-border-2);
}
.tpsideinfo__search button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.tpsideinfo__nabtab .nav-link.active {
  border: 0;
  padding: 10px 15px;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  background-color: var(--tp-heading-secondary);
}
.tpsideinfo__nabtab .nav-link {
  border: 0;
  padding: 10px 15px;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  color: var(--tp-theme-1);
  background-color: var(--tp-common-white);
}
.tpsideinfo__nabtab button {
  width: 100%;
}
.tpsideinfo__nabtab .nav li {
  display: inline-block;
  width: 49%;
  margin-right: 4px;
}
.tpsideinfo__nabtab .nav li:last-child {
  margin-right: 0;
}
.tpsideinfo__nabtab .mega-menu-title {
  color: var(--tp-heading-secondary);
  font-size: 13px;
  text-transform: uppercase;
}
.tpsideinfo__nabtab .mean-container .mean-nav ul {
  background-image: none !important;
}
.tpsideinfo__nabtab .home-menu-style li {
  width: 50% !important;
}
.tpsideinfo__nabtab .home-menu-style li a {
  font-size: 13px !important;
  font-weight: 400 !important;
}
.tpsideinfo__account-link,
.tpsideinfo__wishlist-link {
  border-bottom: 1px #4d49a1 dotted;
  padding-top: 5px;
  padding-bottom: 5px;
}
.tpsideinfo__account-link a,
.tpsideinfo__wishlist-link a {
  font-size: 16px;
  color: var(--tp-common-white);
}
.tpsideinfo__account-link a:hover,
.tpsideinfo__wishlist-link a:hover {
  color: var(--tp-heading-secondary);
}
.tpsideinfo__account-link a:hover i,
.tpsideinfo__wishlist-link a:hover i {
  color: var(--tp-heading-secondary);
}
.tpsideinfo__account-link a i,
.tpsideinfo__wishlist-link a i {
  font-size: 18px;
  color: var(--tp-common-white);
  line-height: 35px;
  text-align: center;
  margin-right: 10px;
}

.tpsidebar-categories ul li {
  list-style: none;
  display: block;
  text-align: left;
  list-style: none;
  text-transform: capitalize;
  padding: 5px 0px 10px 0;
  border-bottom: 1px #4d49a1 dotted;
  font-family: Jost;
}
.tpsidebar-categories ul li a {
  color: var(--tp-common-white);
}

.secondary-header,
.secondary-mobile-menu {
  border-bottom: none;
}

.header-three__search form {
  position: relative;
}
.header-three__search form input {
  height: 40px;
  width: 260px;
  border-radius: 30px;
  border: none;
  background-color: #f4f4f4;
  display: block;
  padding: 5px 20px 5px 45px;
}
.header-three__search form input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #79819c;
}
.header-three__search form input:-moz-placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #79819c;
}
.header-three__search form input::-moz-placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #79819c;
}
.header-three__search form input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #79819c;
}
.header-three__search form i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  margin-right: 8px;
  font-size: 16px;
  color: var(--tp-common-black);
}

.header-logo-border {
  padding: 30px 0;
  border-bottom: 1px solid var(--tp-border-1);
}

.mainmenu-three ul li a {
  padding: 22px 12px;
}

.header-sticky {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99999;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  background: var(--tp-common-white);
}

/*----------------------------------------*/
/*  04. SLIDER CSS
/*----------------------------------------*/
.tpslider {
  height: 750px;
  background-position: bottom;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider {
    padding-top: 50px;
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider {
    padding-top: 50px;
    height: 550px;
  }
}
@media (max-width: 767px) {
  .tpslider {
    padding-top: 50px;
    height: 700px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider {
    padding-top: 50px;
    height: 450px;
  }
}
.tpslider__content:hover .tpslider__shape-fruits1 {
  transform: translateX(15px);
}
.tpslider__content p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpslider__content p {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__content p {
    margin-bottom: 20px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__content p br {
    display: none;
  }
}
.tpslider__sub-title {
  font-size: 16px;
  color: var(--tp-heading-secondary);
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__sub-title {
    margin-bottom: 20px;
  }
}
.tpslider__title {
  font-weight: 700;
  font-size: 65px;
  color: var(--tp-heading-primary);
  line-height: 1.02;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpslider__title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider__title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__title {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .tpslider__title {
    margin-bottom: 20px;
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__title {
    margin-bottom: 20px;
    font-size: 27px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__title br {
    display: none;
  }
}
.tpslider__thumb {
  margin-left: 70px;
}
@media (max-width: 767px) {
  .tpslider__thumb {
    padding-top: 50px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__thumb {
    padding-top: 50px;
    margin-left: 0px;
  }
}
.tpslider__thumb > img {
  max-width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__thumb > img {
    max-width: 310px;
    margin-left: -20px;
  }
}
.tpslider__thumb:hover .tpslider__shape-one,
.tpslider__thumb:hover .tpslider__shape-two,
.tpslider__thumb:hover .tpslider__shape-three,
.tpslider__thumb:hover .tpslider__shape-four,
.tpslider__thumb:hover .tpslider__shape-five,
.tpslider__thumb:hover .three-shap-two,
.tpslider__thumb:hover .three-shap-three,
.tpslider__thumb:hover .three-shap-four {
  transform: translateX(15px);
}
.tpslider__shape img {
  position: absolute;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__shape img {
    height: 140px;
    width: 140px;
  }
}
.tpslider__shape-one {
  bottom: -10px;
  left: -325px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpslider__shape-one {
    left: -190px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider__shape-one {
    left: -120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__shape-one {
    bottom: -150px;
    left: -225px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpslider__shape-one {
    left: -200px;
    bottom: -20px;
    height: 100px;
    width: 120px;
  }
}
.tpslider__shape-two {
  bottom: -150px;
  left: 190px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpslider__shape-two {
    left: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider__shape-two {
    left: 0;
    bottom: -165px;
    height: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__shape-two {
    left: 90px;
  }
}
.tpslider__shape-three {
  top: 5px;
  left: -45px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider__shape-three {
    top: -100px;
    left: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__shape-three {
    top: -105px;
    left: 150px;
  }
}
.tpslider__shape-four {
  top: -70px;
  right: -125px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpslider__shape-four {
    left: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpslider__shape-four {
    top: -80px;
    left: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpslider__shape-four {
    left: -60px;
  }
}
.tpslider__shape-fruits1 {
  position: absolute;
  left: -250px;
  bottom: -90px;
}
.tpslider__shape-fruits1 img {
  height: 120px;
  width: 120px;
}
.tpslider__five__brand {
  animation: lara 2s infinite linear;
}
.tpslider__five__title {
  font-weight: 700;
  font-size: 64px;
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .tpslider__five__title {
    font-size: 42px;
  }
}
.tpslider__five__contact p {
  color: var(--tp-common-white);
  font-size: 16px;
  margin-bottom: 60px;
}
.tpslider__bg6 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 650px;
  background-position: center;
}

.tpsliderarrow {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: 100px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tpsliderarrow {
    bottom: 12%;
    left: 50px;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .tpsliderarrow {
    left: 25px;
  }
}
.tpsliderarrow i {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--tp-heading-secondary);
  border-radius: 50%;
  color: var(--tp-common-white);
  font-size: 18px;
  display: block;
  opacity: 0.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpsliderarrow i {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.tpsliderarrow i:hover {
  opacity: 1;
}

.tpslider__arrow-nxt {
  left: auto;
  right: 100px;
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .tpslider__arrow-nxt {
    right: 25px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tpslider__arrow-nxt {
    right: 50px;
  }
}

.tpslider-delay:hover .tpsliderarrow {
  opacity: 1;
  visibility: visible;
}

.tpslider-delay .swiper-slide-active .tpslider__sub-title,
.tpslider-delay .swiper-slide-active .tpslider__title,
.tpslider-delay .swiper-slide-active p,
.tpslider-delay .swiper-slide-active .tpslider__btn,
.tpslider-delay .swiper-slide-active .tpslider__five__title,
.tpslider-delay .swiper-slide-active .tpslider__five__contact p,
.tpslider-delay .swiper-slide-active .tpslider__five__btn {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.tpslider-delay .swiper-slide-active .tpslider__thumb-img {
  animation-fill-mode: both;
  animation-name: fadeInRight;
}

.tpslider-delay .swiper-slide-active .tpslider__sub-title {
  animation-delay: 0.3s;
  animation-duration: 0.3s;
}

.tpslider-delay .swiper-slide-active .tpslider__title {
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}

.tpslider-delay .swiper-slide-active p {
  animation-delay: 0.7s;
  animation-duration: 0.7s;
}

.tpslider-delay .swiper-slide-active .tpslider__btn {
  animation-delay: 0.9s;
  animation-duration: 0.8s;
}

.tpslider-delay .swiper-slide-active .tpslider__thumb-img {
  animation-delay: 0.2s;
  animation-duration: 0.5s;
}

.tpslider-delay .swiper-slide-active .tpslider__five__title {
  animation-delay: 0.7s;
  animation-duration: 0.7s;
}

.tpslider-delay .swiper-slide-active .tpslider__five__contact p {
  animation-delay: 0.8s;
  animation-duration: 0.8s;
}

.tpslider-delay .swiper-slide-active .tpslider__five__btn {
  animation-delay: 0.9s;
  animation-duration: 0.9s;
}

.slider-pagination {
  position: absolute;
  bottom: 35px !important;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.slider-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--tp-heading-secondary);
  height: 8px !important;
  width: 8px !important;
  border-radius: 50%;
}
.slider-pagination .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  display: inline-block;
  border-radius: 5px;
}

.tpslider-secondary {
  background-position: top;
}

.secondary-sliderbg .tpslider {
  padding-top: 30px;
  height: 840px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .secondary-sliderbg .tpslider {
    height: 600px;
  }
}
@media (max-width: 767px) {
  .secondary-sliderbg .tpslider {
    height: 750px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .secondary-sliderbg .tpslider {
    height: 400px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .secondary-sliderbg .tpslider__bg2 {
    height: 750px;
  }
}
.secondary-sliderbg .tpslider__shape-three {
  top: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .secondary-sliderbg .tpslider__shape-three {
    top: 0;
    height: 80px;
    width: 100px;
  }
}
.secondary-sliderbg .tpslider__shape-four {
  top: 150px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .secondary-sliderbg .tpslider__shape-four {
    top: 200px;
    height: 100px;
    right: 0;
  }
}
.secondary-sliderbg .tpslider__shape-five {
  top: 15px;
  right: -125px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .secondary-sliderbg .tpslider__shape-five {
    top: -27px;
    right: -40px;
    height: 100px;
    width: 120px;
  }
}

.slider-three .tpslider__title {
  font-size: 48px;
  letter-spacing: -0.3px;
  line-height: 1.05;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-three .tpslider__title {
    font-size: 42px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-three .tpslider__title {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .slider-three .tpslider__title {
    font-size: 28px;
  }
}
.slider-three .tpslider__content p {
  margin-bottom: 45px;
}
.slider-three .tpslider__thumb {
  margin-left: 0;
}
.slider-three .three-shap-one {
  top: 120px;
  left: 60px;
}
.slider-three .three-shap-two {
  bottom: 340px;
  right: -10px;
  position: absolute;
}
.slider-three .three-shap-three {
  bottom: 185px;
  right: 70px;
}
.slider-three .three-shap-four {
  top: 0;
  right: 220px;
}
.slider-three .tpslider__thumb > img {
  max-width: 100%;
}

.slider-three-content {
  margin-top: -220px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-three-content {
    margin-top: 0;
  }
}

.slider-three-content-two {
  margin-top: -140px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-three-content-two {
    margin-top: 0;
  }
}

.tpslider__three:hover {
  transform: translateX(-15px);
}

.slider-pagination-6 {
  position: absolute;
  bottom: 35px !important;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.slider-pagination-6 .swiper-pagination-bullet {
  background: var(--tp-common-white);
  opacity: 0.4;
  margin: 0 7px;
}
.slider-pagination-6 .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--tp-heading-secondary);
  height: 8px !important;
  width: 8px !important;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .sections__wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*----------------------------------------*/
/*  05. TEAM CSS
/*----------------------------------------*/
/*----------------------------------------*/
/*  06. SERVICES CSS
/*----------------------------------------*/
/*----------------------------------------*/
/*  07. PORTFOLIO CSS
/*----------------------------------------*/
/*----------------------------------------*/
/*  08. BLOG CSS
/*----------------------------------------*/
.tpblog__item:hover .tpblog__thumb img {
  transform: scale(1.05);
}
.tpblog__thumb {
  border-radius: 10px 10px 0 0;
}
.tpblog__thumb img {
  width: 100%;
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}
.tpblog__entry-wap span {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 8px;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpblog__entry-wap span {
    margin-right: 4px;
    padding-right: 7px;
  }
}
.tpblog__entry-wap span:last-child {
  margin-right: 0;
  padding-right: 0;
}
.tpblog__entry-wap span::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #cfd4de;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}
.tpblog__entry-wap span:last-child::after {
  display: none;
}
.tpblog__entry-wap span a:hover {
  color: var(--tp-heading-secondary);
}
.tpblog__entry-wap .cat-links {
  color: var(--tp-heading-secondary);
}
.tpblog__wrapper {
  border-radius: 0 0 10px 10px;
  background-color: var(--tp-common-white);
  padding: 20px 28px 20px 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  (max-width: 767px) {
  .tpblog__wrapper {
    padding: 20px 20px 20px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpblog__wrapper {
    padding: 20px 15px 20px 15px;
  }
}
.tpblog__wrapper p {
  margin-bottom: 25px;
  font-size: 16px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpblog__wrapper p {
    margin-bottom: 15px;
  }
}
.tpblog__title {
  font-weight: 700;
  font-size: 18px;
  color: var(--tp-theme-1);
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tpblog__title {
    font-size: 16px;
  }
}
.tpblog__title a:hover {
  color: var(--tp-heading-secondary);
}
.tpblog__details {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--tp-heading-secondary);
}
.tpblog__details a {
  display: flex;
  align-items: center;
}
.tpblog__details a:hover i {
  opacity: 1;
  visibility: visible;
  animation: shakenext 1s ease infinite;
}
.tpblog__details a i {
  display: inline-block;
  font-size: 16px;
  margin-left: 3px;
  opacity: 0;
  visibility: hidden;
}
.tpblog__item-2 .tpblog__thumb {
  border-radius: 10px;
}
.tpblog__item-2 .tpblog__wrapper {
  padding: 20px 28px 20px 0px;
}
.tpblog__single-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.22;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tpblog__single-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpblog__single-title {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .tpblog__single-title {
    font-size: 16px;
    margin-bottom: 8px;
  }
}
.tpblog__single-img {
  position: relative;
}
.tpblog__single-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: rgba(10, 12, 31, 0.5);
}
.tpblog__single-img img {
  width: 100%;
  border-radius: 10px;
}
.tpblog__single-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tpblog__single-text .tpblog__entry-wap span {
  color: var(--tp-common-white);
}
.tpblog__single-text > a {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  text-transform: uppercase;
  color: var(--tp-common-white);
}
.tpblog__left-item {
  padding-right: 50px;
  border-right: 1px dashed #e6ecf0;
  margin-right: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpblog__left-item {
    padding-right: 30px;
    margin-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpblog__left-item {
    padding-right: 0;
    border-right: none;
    margin-right: 0;
  }
}
.tpblog__left-item .tpblog__title {
  font-size: 24px;
}
.tpblog__right-item {
  margin-left: -60px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tpblog__right-item {
    margin-left: 0;
  }
}

.sidebar__search input {
  background: #f7f7f9;
  height: 50px;
  width: 100%;
  border: 1px solid #ebeff4;
  border-radius: 3px;
  padding: 5px 40px 5px 20px;
}
.sidebar__search input::-webkit-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #79819c;
}
.sidebar__search input:-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #79819c;
}
.sidebar__search input::-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #79819c;
}
.sidebar__search input:-ms-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #79819c;
}
.sidebar__search button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar__widget {
  padding-bottom: 40px;
  border-bottom: 1px dashed #e6ecf0;
}
.sidebar__widget:last-child {
  border-bottom: none;
}
.sidebar__widget-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.sidebar__widget-content ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sidebar__widget-content ul li a {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.22;
  color: var(--tp-text-body);
}
.sidebar__widget-content ul li a:hover {
  color: var(--tp-heading-secondary);
}

.rc__post-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
  margin-bottom: 4px;
}
.rc__post-thumb {
  flex: 0 0 auto;
  margin-right: 10px;
}
.rc__post-thumb img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.rc__meta {
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-text-3);
}

.tagcloud a {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-text-body);
  background: var(--tp-border-3);
  padding: 8px 20px;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 5px;
}
.tagcloud a:hover {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}

.tp-blog-details__thumb img {
  max-width: 100%;
}
.tp-blog-details__img-item img {
  max-width: 100%;
  border-radius: 10px;
}
.tp-blog-details__wrapper {
  padding: 35px 70px 30px 275px;
  margin-top: -120px;
  background-color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-blog-details__wrapper {
    padding: 35px 70px 30px 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog-details__wrapper {
    padding: 35px 30px 30px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-blog-details__wrapper {
    padding: 35px 15px 30px 15px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .tp-blog-details__wrapper {
    padding: 35px 15px 30px 15px;
    margin-top: 0;
  }
}
.tp-blog-details__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.11;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .tp-blog-details__title {
    font-size: 18px;
  }
}
.tp-blog-details__content p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--tp-text-body);
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .tp-blog-details__content p {
    font-size: 16px;
  }
}
.tp-blog-details__quation {
  font-family: var(--tp-ff-jost);
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.25;
  color: var(--tp-heading-primary);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-blog-details__quation {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .tp-blog-details__quation {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .tp-blog-details__quation br {
    display: none;
  }
}
.tp-blog-details__post-item span {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: var(--tp-text-3);
  display: block;
  margin-bottom: 10px;
}
.tp-blog-details__post-item span i {
  font-size: 12px;
}
.tp-blog-details__post-item a {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
  display: block;
}
.tp-blog-details__post-item a:hover {
  color: var(--tp-heading-secondary);
}
.tp-blog-details__author {
  background-color: var(--tp-grey-8);
  padding: 40px;
  border: 1px solid #ebebf3;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tp-blog-details__author {
    flex-wrap: wrap;
    padding: 40px 15px 40px 15px;
  }
}
.tp-blog-details__author-img {
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .tp-blog-details__author-img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.tp-blog-details__author-img img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.tp-blog-details__author-title {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.tp-blog-details__author-text p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #4d5574;
  margin-bottom: 20px;
}
.tp-blog-details__author-text .author-btn {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-primary);
  padding: 11px 33px;
  border-radius: 30px;
}
.tp-blog-details__author-text .author-btn:hover {
  background-color: var(--tp-heading-secondary);
}

.postbox__tag-list span,
.postbox__social-tag span {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
  display: inline-block;
  margin-right: 8px;
}
.postbox__social-tag {
  text-align: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .postbox__social-tag {
    text-align: start;
  }
}
.postbox__social-tag a {
  margin-left: 5px;
}
.postbox__tag-border {
  padding-top: 15px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e6ecf0;
}
.postbox__comment ul li {
  list-style: none;
}
.postbox__comment-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.postbox__comment-avater img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.postbox__comment-box {
  border-bottom: 1px dashed #e6ecf0;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .postbox__comment-box {
    padding-left: 0;
  }
}
.postbox__comment-name h5 {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.postbox__comment-name p {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
}
.postbox__comment-text p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
}
@media (max-width: 767px) {
  .postbox__comment-text p br {
    display: none;
  }
}
.postbox__comment-reply a {
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-heading-secondary);
}
.postbox__form p {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
  margin-bottom: 25px;
}
.postbox__check-box label {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body) !important;
}

.blog-left-sidebar .tpblog__left-item {
  padding-left: 50px;
  border-left: 1px dashed #e6ecf0;
  margin-left: 80px;
  padding-right: 0px;
  border-right: none;
  margin-right: 0px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-left-sidebar .tpblog__left-item {
    padding-left: 0px;
    border-left: none;
    margin-left: 0px;
  }
}
.blog-left-sidebar.tpblog__right-item {
  margin-left: 0;
  margin-right: -60px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-left-sidebar.tpblog__right-item {
    margin-right: 0;
  }
}

/*----------------------------------------*/
/*  09. EFFECT CSS
/*----------------------------------------*/
.tpcolor__purple a i {
  background-color: var(--tp-grey-3);
}
.tpcolor__yellow a i {
  background-color: var(--tp-grey-4);
}
.tpcolor__greenish a i {
  background-color: var(--tp-grey-5);
}
.tpcolor__oasis a i,
.tpcolor__oasis button i {
  background-color: var(--tp-grey-6);
}

.tperror__thumb > img {
  animation: left-right 3s infinite linear;
  max-width: 100%;
}
.tperror__shape {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-60%);
  z-index: -1;
}
.tperror__shape img {
  max-width: 100%;
}
.tperror__title {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 48px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .tperror__title {
    font-size: 22px;
  }
}
.tperror__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
  margin-bottom: 45px;
}
.tperror__btn a {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  padding: 16px 35px;
  border-radius: 30px;
}
.tperror__btn a:hover {
  background-color: #859a00;
}

.tpvideo__bg img {
  border-radius: 10px;
  max-width: 100%;
}
.tpvideo__video-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}
.tpvideo__video-btn i {
  height: 60px;
  width: 60px;
  line-height: 55px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--tp-common-white);
}
@media (max-width: 767px) {
  .tpvideo__video-btn i {
    height: 40px;
    width: 40px;
    line-height: 37px;
  }
}
.tpvideo__icon i {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  text-transform: uppercase;
  color: #96ae00;
  display: flex;
  align-items: baseline;
}
.tpvideo__icon i img {
  max-width: 100%;
  flex: 0 0 auto;
  margin-right: 5px;
}
.tpvideo__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--tp-heading-primary);
}
.tpvideo__content p {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpvideo__content p br {
    display: none;
  }
}

/*----------------------------------------*/
/*  10. CATEGORY CSS
/*----------------------------------------*/
.category__item {
  text-align: center;
  background-color: var(--tp-common-white);
  border-radius: 10px;
  padding: 15px 10px 10px 10px;
}
.category__item:hover .category__thumb::after {
  opacity: 1;
  visibility: visible;
}
.category__item:hover .category__thumb::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transform: scale3d(1.08, 1.08, 1.08);
}
.category__thumb {
  display: inline-block;
  border-radius: 50%;
  position: relative;
}
.category__thumb::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: linear-gradient(#ffffff 0 0), linear-gradient(#ffffff 0 0);
  background-position: center;
  background-size: 100% 1px, 1px 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
  pointer-events: none;
}
.category__thumb::before {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  z-index: 2;
  right: 50%;
  bottom: 50%;
  opacity: 0;
  transition: all 240ms linear 0s;
  pointer-events: none;
}
.category__thumb img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.category__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  color: var(--tp-heading-primary);
  margin-bottom: 0;
  font-family: "Jost", sans-serif;
}
.category__title a:hover {
  color: var(--tp-heading-secondary);
}
.category__count {
  font-weight: 400;
  font-size: 13px;
  color: var(--tp-text-body);
}

/*----------------------------------------*/
/*  11. PRODUCT CSS
/*----------------------------------------*/
.tpproduct {
  background-color: var(--tp-common-white);
  border-radius: 10px;
  border: 1px solid var(--tp-common-white);
}
.tpproduct:hover .tpproduct__shopping a {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px);
}
.tpproduct:hover .tpproduct__hover-text {
  border: 1px solid var(--tp-common-white);
  border-top: transparent;
  opacity: 1;
  visibility: visible;
  bottom: -130px;
  box-shadow: 0px 15px 30px rgba(61, 110, 168, 0.1);
  border-radius: 0 0 10px 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpproduct:hover .tpproduct__hover-text {
    bottom: -135px;
  }
}
.tpproduct:hover .tpproduct__thumb-img {
  opacity: 1;
  visibility: visible;
  transform: scale(1.09);
}
.tpproduct__thumb {
  padding: 20px 20px;
  overflow: hidden;
  border-radius: 10px;
  height:220px;
  display: flex;
    align-items: center;
}
.tpproduct__thumb-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.tpproduct__thumb img {
  width: auto;
  max-height: 200px;
    margin: 0 auto;
}
.tpproduct__content {
  background-color: #fff;
  padding: 11px 22px 20px 18px;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpproduct__content {
    padding: 11px 13px 20px 13px;
  }
}
.tpproduct__content-weight {
  font-size: 13px;
  color: var(--tp-text-3);
  display: inline;
}
.tpproduct__content-weight a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpproduct__content-weight a:hover {
  text-decoration: underline;
}
.tpproduct__title {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-heading-primary);
  margin-bottom: 5px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
  overflow: hidden;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tpproduct__title {
    font-size: 15px;
  }
}
.tpproduct__title a:hover {
  color: var(--tp-heading-secondary);
}
.tpproduct__rating a i {
  margin-right: -5px;
  font-size: 14px;
  color: var(--tp-text-1);
}
.tpproduct__price span {
  color: var(--bs-gray-dark);
  font-weight: 500;
  font-size: 18px;
  margin-Right: 10px;
}
.min-he {
  min-height: 30vh;
}
del {
  color:var(--bs-red);
}
.tpproduct__price del {
  font-weight: 400;
  font-size: 14px;
}
.tpproduct__info {
  position: absolute;
  top: 17px;
  left: 17px;
}
.tpproduct__shopping {
  position: absolute;
  top: 17px;
  right: 17px;
}
.tpproduct__shopping a {
  opacity: 0;
  visibility: hidden;
}
.tpproduct__shopping a:first-child {
  transition-delay: 100ms;
}
.tpproduct__shopping a:nth-child(2) {
  transition-delay: 150ms;
}
.tpproduct__shopping a:last-child {
  transition-delay: 200ms;
}
.tpproduct__shopping a > i {
  font-size: 12px;
  height: 32px;
  width: 32px;
  line-height: 30px;
  text-align: center;
  background: var(--tp-common-white);
  border: 1px solid var(--tp-border-1);
  border-radius: 50%;
  display: block;
  margin-bottom: 5px;
}
.tpproduct__shopping a > i:hover {
  background-color: var(--tp-heading-secondary);
  border: 1px solid var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.tpproduct__hover-text {
  background-color: var(--tp-grey-8);
  padding: 11px 21px 20px 19px;
  position: absolute;
  bottom: -125px;
  left: -1px;
  right: -1px;
  border: 1px solid var(--tp-common-white);
  border-top: transparent;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpproduct__hover-text {
    bottom: -120px;
    padding: 11px 15px 20px 15px;
  }
}
.tpproduct__descrip ul li {
  font-size: 13px;
  color: var(--tp-text-3);
  list-style: none;
  padding-left: 10px;
  position: relative;
}
.tpproduct__descrip ul li::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 3px;
  background-color: var(--tp-grey-7);
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tpproduct__arrow:hover .tpprduct-arrow {
  opacity: 1;
  visibility: visible;
}
.tpproduct__all-item span {
  font-size: 16px;
  color: var(--tp-text-body);
}
.tpproduct__all-item span a {
  font-weight: 500;
  font-size: 16px;
  color: var(--tp-heading-secondary);
  display: inline-flex;
  align-items: center;
}
.tpproduct__all-item span a:hover i {
  animation: shakenext 1s ease infinite;
}
.tpproduct__all-item span a i {
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
}
.tpproduct__all-item {
  text-align: end;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .tpproduct__all-item {
    text-align: start;
  }
}
.tpproduct__progress .progress {
  height: 7px;
  width: 100%;
}
.tpproduct__progress .progress-bar {
  color: #ecf0e8;
  background-color: var(--tp-heading-secondary);
}
.tpproduct__progress span {
  font-size: 14px;
  color: var(--tp-heading-primary);
  font-weight: 400;
}
.tpproduct__progress span b {
  font-weight: 500;
  color: var(--tp-heading-primary);
}
.tpproduct__all-item a {
  font-size: 15px;
  font-weight: 400;
  color: var(--tp-text-3);
}
.tpproduct__all-item a i {
  margin-left: -4px;
}
.tpproduct__all-item a:hover {
  color: var(--tp-heading-secondary);
}
@media (max-width: 767px) {
  .tpproduct__padding {
    padding-left: 0;
    padding-right: 0;
  }
}
.tpproduct__big-title {
  font-size: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpproduct__big-title {
    font-size: 18px;
  }
}
.tpproduct__big-price span {
  font-size: 24px;
}

.deals-label {
  font-size: 16px;
  color: var(--tp-theme-1);
}

.bage__discount {
  font-weight: 500;
  font-size: 12px;
  background-color: var(--tp-theme-3);
  border-radius: 2px;
  color: var(--tp-common-white);
  padding: 3px 9px;
}
.bage__hot {
  font-weight: 500;
  font-size: 12px;
  background-color: var(--tp-text-4);
  border-radius: 2px;
  color: var(--tp-theme-2);
  padding: 3px 9px;
}

.tpproduct__arrow:hover .swiper-container {
  padding-bottom: 150px;
  margin-bottom: -150px;
}

.tpprduct-arrow {
  position: absolute;
  top: 37%;
  left: -27px;
  z-index: 9;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.tpprduct-arrow.tpproduct-btn__nxt {
  left: auto;
  right: -25px;
}
.tpprduct-arrow i {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: var(--tp-heading-secondary);
  opacity: 0.3;
  color: var(--tp-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpprduct-arrow i:hover {
  opacity: 1;
}
.tpprduct-arrow.tpproduct-btn__nxt4 {
  left: auto;
  right: -25px;
}

.whight-product .tpproduct {
  border: 1px solid #ebeff4;
}
.whight-product .tpproduct__content {
  background-color: var(--tp-common-white);
}
.whight-product .tpproduct:hover .tpproduct__hover-text {
  border: 1px solid #ebeff4;
  border-top: none;
  background-color: var(--tp-common-white);
  position: absolute;
  z-index: 11;
}

.tpprogress__hover .tpproduct__hover-text {
  bottom: -80px;
}
.tpprogress__hover:hover .tpproduct__hover-text {
  bottom: -90px;
}

.tp-navtab-style-2 {
  display: flex;
  justify-content: end;
}
@media (max-width: 767px) {
  .tp-navtab-style-2 {
    justify-content: start;
  }
}
.tp-navtab-style-2.tpnavtab__area nav .nav-link {
  color: var(--tp-text-body);
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
  background: 0 0;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0 0px;
  margin-bottom: 8px;
  margin-left: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-navtab-style-2.tpnavtab__area nav .nav-link {
    font-size: 14px;
    margin-left: 14px;
  }
}
@media (max-width: 767px) {
  .tp-navtab-style-2.tpnavtab__area nav .nav-link {
    font-size: 14px;
    margin-left: 0;
    margin-right: 14px;
  }
}
.tp-navtab-style-2 .nav-link.active {
  color: var(--tp-heading-secondary) !important;
}

.green-product-border .green-border {
  border: 1px solid var(--tp-heading-secondary);
}
.green-product-border .green-border:hover .tpproduct__hover-text {
  border: 1px solid var(--tp-heading-secondary);
  border-top: 0px;
}

.tplist__product {
  background-color: var(--tp-common-white);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .tplist__product {
    flex-wrap: wrap;
  }
}
.tplist__product:hover .tplist__product-img-one {
  opacity: 0;
  visibility: hidden;
}
.tplist__product:hover .tplist__product-img-two {
  opacity: 1;
  visibility: visible;
  transform: scale(1.08);
}
.tplist__product-img {
  position: relative;
  flex: 0 0 auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  overflow: hidden;
}
.tplist__product-img .tplist__product-img-two {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tplist__content {
  margin-left: -230px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tplist__content {
    margin-left: 0;
  }
}
.tplist__content span {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: var(--tp-text-3);
}
.tplist__content-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--tp-ff-jost);
}
.tplist__content-title a:hover {
  color: var(--tp-heading-secondary);
}
.tplist__content-info li {
  font-family: var(--tp-ff-jost);
  color: var(--tp-text-3);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  position: relative;
  padding-left: 16px;
  list-style: none;
}
.tplist__content-info li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--tp-text-3);
}
.tplist__rating a i {
  margin-right: -5px;
  font-size: 14px;
  color: var(--tp-text-1);
}
.tplist__instock {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--tp-text-body);
  margin-bottom: 0;
}
.tplist__instock span {
  color: #00b853;
}
.tplist__count {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ea0d42;
}
.tplist__shopping a {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: var(--tp-text-3);
  text-transform: uppercase;
  margin-right: 20px;
}
.tplist__shopping a i {
  font-size: 14px;
  margin-right: 5px;
}
.tplist__price {
  position: relative;
  padding-left: 30px;
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .tplist__price {
    padding-left: 0;
    margin-top: 30px;
  }
}
.tplist__price::before {
  position: absolute;
  content: "";
  left: 0;
  height: 200px;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ebeff4;
}
@media (max-width: 767px) {
  .tplist__price::before {
    display: none;
  }
}
.tplist__price .tp-btn-2 {
  padding: 3px 58px;
}

.product__table-count .product__details-count {
  display: flex;
}
.product__table-title a {
  color: var(--tp-heading-primary);
  font-size: 16px;
}
.product__table table td {
  text-align: center;
  vertical-align: inherit;
  padding: 15px 15px 15px 15px;
  width: auto;
  text-align: left;
  border: 1px solid var(--tp-border-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__table table td {
    width: 200%;
  }
}

/*----------------------------------------*/
/*  12. PRODUCT-DETAILS CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpdetails__area {
    margin-right: 0;
  }
}
.tpdetails__product {
  background-color: var(--tp-common-white);
  padding: 35px 40px 45px 40px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tpdetails__product {
    padding: 20px 20px 16px 20px;
  }
}
.tpdetails__title-box {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e6ecf0;
}
.tpdetails__brand li {
  list-style: none;
  display: inline-block;
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #455770;
  padding-right: 20px;
  position: relative;
  margin-right: 15px;
}
.tpdetails__brand li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.tpdetails__brand li:last-child::before {
  display: none;
}
.tpdetails__brand li::before {
  position: absolute;
  content: "";
  right: 0;
  height: 12px;
  width: 1px;
  background-color: #e6ecf0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .tpdetails__brand li::before {
    display: none;
  }
}
.tpdetails__brand li a {
  display: inline-block;
  font-weight: 500;
  color: var(--tp-heading-secondary);
}
.tpdetails__brand li i {
  margin-right: -5px;
  font-size: 14px;
  color: var(--tp-text-1);
}
.tpdetails__brand li span {
  font-weight: 500;
  color: var(--tp-heading-secondary);
  display: inline-block;
}
.tpdetails__brand li b {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--tp-text-3);
  margin-left: 5px;
}
.tpdetails__title {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: var(--tp-heading-primary);
}

.tpproduct-details__thumb-img img {
  max-width: 100%;
}
@media (max-width: 767px) {
  .tpproduct-details__nab {
    margin-bottom: 30px;
  }
}
.tpproduct-details__nab .tab-pane {
  position: relative;
}
.tpproduct-details__nab .nav-tabs {
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}
.tpproduct-details__nab .nav-tabs .nav-link {
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0 5px;
  border: 1px solid #e6ecf0;
  border-radius: 5px;
}
.tpproduct-details__nab .nav-tabs .nav-link.active {
  border-color: var(--tp-heading-secondary);
}
.tpproduct-details__nab .nav-tabs .nav-link img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.product__details-price-box {
  padding-bottom: 25px;
  border-bottom: 1px solid #e6ecf0;
  margin-bottom: 30px;
}
.product__details-price {
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #ea0d42;
}
.product__details-info-list li {
  font-family: var(--tp-ff-jost);
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--tp-text-body);
  position: relative;
  padding-left: 18px;
}
.product__details-info-list li::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tp-text-body);
  border-radius: 50%;
}
.product__details-cart {
  padding-bottom: 20px;
  border-bottom: 1px solid #e6ecf0;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .product__details-quantity {
    flex-wrap: wrap;
  }
}
.product__details-quantity b {
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #2d2a6e;
  flex: 0 0 auto;
  margin-right: 8px;
}
.product__details-count {
  flex: 0 0 auto;
  background-color: #f3f3f9;
  border-radius: 30px;
  display: inline-block;
  border: 1px solid #ebeff4;
  padding: 10px 29px;
  color: var(--tp-text-body);
  cursor: pointer;
}
.product__details-count input {
  background-color: #f3f3f9;
  border: none;
  width: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--tp-text-body);
}
.product__details-btn {
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .product__details-btn {
    margin-top: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product__details-btn {
    margin-top: 0px;
  }
}
.product__details-btn a {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  padding: 13px 97px;
  border-radius: 30px;
  display: inline-block;
}
.product__details-btn a:hover {
  background-color: #859a00;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product__details-btn a {
    padding: 13px 30px;
  }
}
@media (max-width: 767px) {
  .product__details-btn a {
    padding: 13px 30px;
  }
}
.product__details-check {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.product__details-check li {
  list-style: none;
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
  margin-right: 25px;
  margin-bottom: 5px;
}
.product__details-check li:last-child {
  margin-right: 0;
}
.product__details-stock li {
  list-style: none;
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
  margin-bottom: 5px;
}
.product__details-stock li i {
  color: #00b853;
  font-style: normal;
}
.product__details-payment {
  background-color: var(--tp-grey-8);
  padding: 20px;
  border-radius: 5px;
}
.product__details-payment img {
  max-width: 100%;
}
.product__details-payment span {
  display: block;
  margin-top: 5px;
}
.product__color-title {
  font-family: var(--tp-ff-jost);
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #2d2a6e;
}
.product__color-switch .form-check-input:checked {
  background-color: var(--tp-common-black);
  border-color: var(--tp-border-1);
}
.product__color-switch .form-check-input:checked:focus {
  outline: 0;
  border: none;
}
.product__sticky {
  position: sticky;
  top: 100px;
}

.tpdescription__box {
  background-color: var(--tp-common-white);
  padding: 20px 40px 16px 40px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tpdescription__box {
    padding: 20px 20px 16px 20px;
  }
}
.tpdescription__content p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--tp-text-body);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpdescription__product-wrapper {
    flex-wrap: wrap;
  }
}
.tpdescription__product-title {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.tpdescription__product-info li {
  list-style: none;
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--tp-text-body);
}
.tpdescription__product-info p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--tp-text-body);
}
.tpdescription__product-thumb img {
  border-radius: 5px;
  max-width: 100%;
}
.tpdescription__video p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--tp-text-body);
}
.tpdescription__video-wrapper img {
  border-radius: 5px;
}
.tpdescription__box-center {
  border-bottom: 1px solid #e6ecf0;
  margin-bottom: 30px;
}
.tpdescription__box-center .nav-tabs {
  border: none;
  outline: 0;
}
.tpdescription__box-center .nav-tabs .nav-link {
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #79819c;
  padding: 0 0 14px 0;
  margin: 0 22px;
}
@media (max-width: 767px) {
  .tpdescription__box-center .nav-tabs .nav-link {
    margin: 0 8px;
    font-size: 13px;
  }
  .tpsidebar {
    margin-top: 15px;
  }
}
.tpdescription__box-center .nav-tabs .nav-link.active {
  color: var(--tp-heading-secondary);
  border-bottom: 2px solid var(--tp-heading-secondary);
}


@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpsidebar {
    margin-left: 0;
  }
}
.tpsidebar__warning {
  border: 1px solid #e8c3c3;
  border-radius: 10px;
  padding: 25px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpsidebar__warning {
    padding: 25px 15px;
  }
}
.tpsidebar__warning ul li {
  list-style: none;
  padding-bottom: 30px;
  margin-bottom: 26px;
  border-bottom: 1px dashed #e8c3c3;
}
.tpsidebar__warning ul li:last-child {
  padding-bottom: 0;
  border: none;
  margin-bottom: 0;
}
.tpsidebar__warning-item {
  text-align: center;
}
.tpsidebar__warning-text p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b45353;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpsidebar__warning-text p br {
    display: none;
  }
}
.tpsidebar__warning-icon i {
  color: #b45353;
  font-size: 20px;
}
.tpsidebar__banner img {
  max-width: 100%;
}
.tpsidebar__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
}
.tpsidebar__product {
  background: var(--tp-common-white);
  border-radius: 10px;
  padding: 35px 30px 25px 30px;
}
.tpsidebar__product-category {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #79819c;
}
.tpsidebar__product-title {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--tp-heading-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}
.tpsidebar__product-item {
  border-bottom: 1px dashed #e6ecf0;
  margin-bottom: 15px;
  padding-bottom: 12px;
}
.tpsidebar__product-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.tpsidebar__product-thumb img {
  max-width: 100%;
}
.tpsidebar__info {
  position: absolute;
  top: 0;
  left: 0;
}

.tpreview__wrapper-title {
  font-size: 24px;
  color: var(--tp-heading-primary);
  margin-bottom: 20px;
  font-weight: 600;
}
.tpreview__comment {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--tp-border-1);
}
.tpreview__comment-img {
  flex: 0 0 auto;
}
.tpreview__comment-text {
  flex: 0 0 auto;
  border: 1px solid var(--tp-border-1);
  border-radius: 10px;
  padding: 10px;
}
.tpreview__comment-text .date {
  color: #767676;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.tpreview__comment-author {
  font-weight: 600;
  color: var(--tp-heading-primary);
  font-size: 13px;
  text-transform: uppercase;
  flex: 0 0 auto;
  margin-right: 10px;
}
.tpreview__comment-star {
  flex: 0 0 auto;
}
.tpreview__comment-star i {
  font-size: 14px;
  color: var(--tp-text-1);
  margin: 0 0 0 -5px;
}
.tpreview__form-title {
  color: var(--tp-heading-primary);
  font-weight: 600;
  font-size: 20px;
}
.tpreview__input input {
  height: 60px;
  width: 100%;
  border: 1px solid var(--tp-border-1);
  border-radius: 30px;
  padding: 5px 30px;
}
.tpreview__input input:focus {
  border: 1px solid var(--tp-heading-primary);
}
.tpreview__input input::-webkit-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input input:-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input input::-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input input:-ms-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input textarea {
  width: 100%;
  padding: 10px;
  height: 220px;
  border: 1px solid var(--tp-border-1);
  border-radius: 10px;
  outline: 0;
}
.tpreview__input textarea:focus {
  border: 1px solid var(--tp-heading-primary);
}
.tpreview__input textarea::-webkit-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input textarea:-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input textarea::-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__input textarea:-ms-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpreview__star .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--tp-heading-primary);
}
.tpreview__star-icon i {
  color: var(--tp-heading-primary);
}
.tpreview__star-icon i:hover {
  color: var(--tp-text-1);
}

.tpdetails__grid-img img {
  max-width: 100%;
}

/*----------------------------------------*/
/*  13. BANNER CSS
/*----------------------------------------*/
.tpbanner__content {
  min-height: 220px;
  border-radius: 10px;
  padding: 31px 40px 27px 40px;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpbanner__content {
    background-repeat: no-repeat;
  }
}
.tpbanner__content p {
  color: var(--tp-common-white);
  font-size: 16px;
}
.tpbanner__sub-title {
  color: var(--tp-text-5);
  font-weight: 500;
  font-size: 14px;
  display: block;
  text-transform: uppercase;
}
.tpbanner__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpbanner__title {
    font-size: 20px;
  }
}
.tpbanner__white {
  color: var(--tp-common-white);
}
.tpbanner__bg {
  background-position: right;
  background-size: cover;
  padding: 42px 20px 40px 40px;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpbanner__bg {
    padding: 42px 20px 40px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbanner__bg {
    padding: 42px 20px 40px 20px;
    background-position: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpbanner__bg {
    padding: 55px 15px 35px 15px;
  }
}
.tpbanner__bg .tpbanner__sub-title {
  color: var(--tp-theme-2);
}
.tpbanner__bg .tpbanner__title {
  color: var(--tp-heading-primary);
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.tpbanner__bg p {
  font-size: 16px;
  color: var(--tp-heading-primary);
  margin-bottom: 0;
}
.tpbanner__main-thumb img {
  width: 100%;
}
.tpbanner__main-content {
  padding: 110px 50px;
}
@media (max-width: 767px) {
  .tpbanner__main-content {
    padding: 110px 15px;
  }
}
.tpbanner__main__sub-title {
  color: var(--tp-theme-2);
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbanner__main__sub-title {
    margin-bottom: 5px;
  }
}
.tpbanner__main__title {
  color: var(--tp-theme-1);
  font-family: "Quicksand";
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbanner__main__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .tpbanner__main__title {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
.tpbanner__white {
  color: var(--tp-common-white);
}
.tpbanner__yellow {
  color: var(--tp-text-5);
}
.tpbanner__bg2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 767px) {
  .tpbanner__bg2 {
    background-position: left;
  }
}
.tpbanner__big-bg {
  background-position: right;
  background-size: cover;
  padding: 185px 20px 45px 40px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpbanner__big-bg {
    background-position: left;
  }
}
.tpbanner__big-bg .tpbanner__sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fcde00;
}
.tpbanner__big-bg .tpbanner__title {
  font-weight: 700;
  font-size: 36px;
  color: var(--tp-common-white);
  line-height: 1.2;
  text-transform: uppercase;
}
.tpbanner__big-bg-content p {
  font-size: 16px;
  color: var(--tp-common-white);
  margin-bottom: 50px;
}
.tpbanner__bg3 {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 20px 25px 40px;
  background-position: center;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpbanner__bg3 {
    background-position: left;
  }
}
.tpbanner__bg3 .tpbanner__sub-title {
  color: var(--tp-theme-5);
}
.tpbanner__bg3 .tpbanner__title {
  text-transform: uppercase;
}
.tpbanner__bg3 p {
  color: var(--tp-common-white);
}
.tpbanner__bg4 {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 20px 25px 40px;
  background-position: center;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tpbanner__bg4 {
    background-position: left;
  }
}
.tpbanner__bg4 .tpbanner__sub-title {
  color: var(--tp-theme-5);
}
.tpbanner__bg4 .tpbanner__title {
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbanner__bg4 .tpbanner__title {
    font-size: 18px;
  }
}
.tpbanner__bg4 p {
  color: var(--tp-common-white);
}

.tpbannertwo__sub-title {
  text-transform: uppercase;
  color: var(--tp-text-1);
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.tpbannertwo__title {
  color: var(--tp-common-white);
  font-weight: 700;
  font-size: 24px;
}
.tpbannertwo p {
  color: var(--tp-common-white);
  font-weight: 400;
  font-size: 16px;
}
.tpbannertwo__bg {
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tpbannertwo__bg {
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
  }
}

.tphero__bg {
  background-position: bottom;
  min-height: 840px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tphero__bg {
    min-height: 700px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__bg {
    min-height: 500px;
  }
}
@media (max-width: 767px) {
  .tphero__thumb {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__thumb {
    margin-bottom: 50px;
  }
}
.tphero__thumb .pera2 img {
  width: 100%;
}
.tphero__thumb-shape-one {
  top: 220px;
  right: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tphero__thumb-shape-one {
    top: 175px;
    right: -45px;
  }
}
@media (max-width: 767px) {
  .tphero__thumb-shape-one {
    top: 95px;
    right: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__thumb-shape-one {
    top: 95px;
    right: 0px;
  }
}
.tphero__thumb-shape-two {
  top: 220px;
  right: 45px;
}
.tphero__thumb-shape-three {
  top: 20px;
  right: -145px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .tphero__thumb-shape-three {
    right: 0;
  }
}
.tphero__thumb-shape-four {
  bottom: 180px;
  left: -80px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__thumb-shape-four {
    bottom: 100px;
  }
}
.tphero__thumb-shape-five {
  top: 170px;
  left: -260px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tphero__thumb-shape-five {
    top: 400px;
    left: -100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__thumb-shape-five {
    top: 270px;
    left: -150px;
  }
}
.tphero__thumb-img {
  position: absolute;
}
.tphero__thumb-img img {
  width: 100%;
}
.tphero__wrapper p {
  color: var(--tp-common-white);
  font-size: 18px;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .tphero__wrapper p {
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__wrapper p {
    font-size: 15px;
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__wrapper p br {
    display: none;
  }
}
.tphero__wrapper-shape {
  position: absolute;
  bottom: 25px;
  left: -230px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tphero__wrapper-shape {
    bottom: -150px;
    left: 205px;
  }
}
.tphero__sub-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .tphero__sub-title {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__sub-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.tphero__title {
  font-size: 65px;
  font-weight: 700;
  color: var(--tp-common-white);
  font-family: "Quicksand";
  line-height: 1.1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tphero__title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tphero__title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tphero__title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .tphero__title {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tphero__title {
    font-size: 26px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tphero__title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .tphero__pt {
    padding-top: 30px;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .pera3 img {
    width: 250px;
  }
}

/*----------------------------------------*/
/*  14. COUNDOWN CSS
/*----------------------------------------*/
.tpcoundown {
  padding: 150px 5px 130px 5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpcoundown {
    margin-left: 0;
  }
}
.tpcoundown__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .tpcoundown__bg {
    background-position: right;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpcoundown__bg-green {
    background-position: left;
  }
}
.tpcoundown__count-title {
  color: var(--tp-common-white);
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}
.tpcoundown__countdown {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.tpcoundown__countdown span {
  margin-right: 50px;
  display: flex;
  align-items: end;
}
@media (max-width: 767px) {
  .tpcoundown__countdown span {
    margin-right: 15px;
  }
}
.tpcoundown__countdown span p {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-grey-9);
  margin-bottom: 0;
  transform: translateY(-10px);
  margin-left: 3px;
}
@media (max-width: 767px) {
  .tpcoundown__countdown span p {
    font-size: 10px;
    transform: translateY(-15px);
  }
}
.tpcoundown__countdown .time-count {
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: #fff;
  margin-right: 0;
}
@media (max-width: 767px) {
  .tpcoundown__countdown .time-count {
    font-size: 20px;
  }
}
.tpcoundown__shape img {
  position: absolute;
}
.tpcoundown__shape img:hover {
  animation-name: chara, lara;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
.tpcoundown__shape-one {
  left: -175px;
  top: 140px;
}
.tpcoundown__shape-two {
  right: 600px;
  bottom: 190px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcoundown__shape-two {
    right: 400px;
    bottom: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcoundown__shape-two {
    right: 0px;
    bottom: 0;
  }
}
.tpcoundown__shape-three {
  right: 70px;
  top: 215px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcoundown__shape-three {
    right: 370px;
  }
}
.tpcoundown__shape-four {
  right: 280px;
  bottom: 270px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcoundown__shape-four {
    right: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcoundown__shape-four {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .tpcoundown__btn a {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
.tpcoundown__themebg {
  margin-bottom: 26px;
}
.tpcoundown__themebg .time-count {
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  color: var(--tp-theme-1);
  margin-right: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcoundown__themebg .time-count {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .tpcoundown__themebg .time-count {
    font-size: 16px;
    line-height: 38px;
  }
}
.tpcoundown__themebg span {
  margin-right: 20px;
  display: flex;
  align-items: end;
}
@media (max-width: 767px) {
  .tpcoundown__themebg span {
    margin-right: 15px;
  }
}
.tpcoundown__themebg span p {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--tp-theme-1);
  margin-bottom: 0;
  transform: translateY(-10px);
  margin-left: 3px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcoundown__themebg span p {
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .tpcoundown__themebg span p {
    font-size: 10px;
    line-height: 12px;
  }
}

/*----------------------------------------*/
/*  15. FEATURE CSS
/*----------------------------------------*/
.tpfeature__thumb {
  text-align: right;
  margin-right: -16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfeature__thumb {
    text-align: center;
    margin-right: 0;
  }
}
.tpfeature__thumb img {
  max-width: 100%;
}
.tpfeature__thumb img:hover {
  transform: translateY(-8px);
}
.tpfeature__content p {
  font-size: 16px;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .tpfeature__content p br {
    display: none;
  }
}
.tpfeature__title {
  font-size: 40px;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpfeature__title {
    font-size: 33px;
  }
}
@media (max-width: 767px) {
  .tpfeature__title {
    font-size: 28px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tpfeature__title br {
    display: none;
  }
}
.tpfeature__title span {
  color: var(--tp-heading-secondary);
}
.tpfeature__product-title {
  font-family: "Jost", sans-serif;
  color: var(--tp-heading-primary);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 20px;
  padding: 0px 0px 5px 0px;
  border-style: dashed;
  border-width: 0px 0px 1px 0px;
  border-color: #c2c2d3;
}
.tpfeature__product-info {
  font-size: 16px;
  color: var(--tp-text-body);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpfeature__product-info {
    font-size: 15px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfeature__product-item {
    margin-bottom: 20px;
  }
}
.tpfeature__shape-one {
  position: absolute;
  top: 30px;
  left: 0;
}
.tpfeature__shape-one:hover {
  animation-name: chara, lara;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
.tpfeature__shape-two {
  position: absolute;
  bottom: 320px;
  right: -53px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpfeature__shape-two {
    right: 0px;
  }
}
.tpfeature__shape-two:hover {
  animation-name: chara, lara;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
.tpfeature__shape-three {
  position: absolute;
  top: 0px;
  right: -95px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tpfeature__shape-three {
    right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpfeature__shape-three {
    top: 100px;
    right: 420px;
  }
}
.tpfeature__shape-three:hover {
  animation-name: chara, lara;
  animation-duration: 0.3s, 1.5s;
  animation-delay: 0s, 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfeature__box {
    margin-bottom: 30px;
  }
}
.tpfeature__price-red {
  font-size: 16px;
  color: var(--tp-text-body);
  font-family: var(--tp-ff-jost);
}
.tpfeature__price-red span {
  color: var(--tp-theme-2);
  font-weight: 600;
  font-size: 20px;
}

.tpproduct-feature {
  margin-left: 75px;
  margin-right: 90px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpproduct-feature {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpproduct-feature {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
  }
}

.mainfeature__item:hover .mainfeature__icon img {
  animation: animation-plus 1s infinite linear alternate;
}
.mainfeature__bg {
  background-repeat: repeat-x;
}
.mainfeature__icon {
  margin-bottom: 10px;
}
.mainfeature__icon img {
  height: 25px;
  width: 25px;
}
.mainfeature__content p {
  color: var(--tp-grey-10);
  font-size: 14px;
  margin-bottom: 0;
}
.mainfeature__title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: var(--tp-common-white);
  margin-bottom: 2px;
}
.mainfeature__border {
  border-bottom: 1px solid #484492;
}

.whight-feature .mainfeature__title {
  color: var(--tp-heading-primary);
}
.whight-feature .mainfeature__content p {
  color: var(--tp-text-body);
}

.feature-bg-round {
  border-radius: 20px 20px 0 0;
  padding: 50px 50px;
}
.feature-bg-round .tpfeature-border {
  border-bottom: 1px solid var(--tp-border-1);
}

.feature-top {
  margin-top: -220px;
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px),
  (max-width: 767px) {
  .feature-top {
    margin-top: 0;
  }
}

.tpinsta__item {
  border-radius: 10px;
  position: relative;
}
.tpinsta__item:hover::before {
  opacity: 1;
  transform: scale(1);
}
.tpinsta__item:hover .tpinsta__links {
  opacity: 1;
  visibility: visible;
}
.tpinsta__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  opacity: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.1);
}
.tpinsta__item img {
  width: 100%;
}
.tpinsta__links {
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}
.tpinsta__links i {
  background-color: var(--tp-common-white);
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.tpinsta__links i:hover {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  16. ABOUT CSS
/*----------------------------------------*/
.tpabout__item:hover .tpabout__icon img {
  animation: lara 1s infinite linear;
}
.tpabout__title-img img:hover {
  animation: lara 1s infinite linear;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpabout__title-img img {
    max-width: 100%;
  }
}
.tpabout__title-img p {
  font-size: 18px;
  line-height: 26px;
}
@media (max-width: 767px) {
  .tpabout__title-img p br {
    display: none;
  }
}
.tpabout__title {
  font-weight: 700;
  font-size: 16px;
  color: var(--tp-heading-primary);
  margin-bottom: 15px;
}
.tpabout__border {
  border-bottom: 1px solid var(--tp-border-1);
}
.tpabout__content p {
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpabout__content p br {
    display: none;
  }
}
.tpabout__inner-sub {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--tp-common-white);
}
.tpabout__inner-title {
  font-weight: 700;
  font-size: 60px;
  line-height: 45px;
  letter-spacing: -0.03em;
  color: var(--tp-common-white);
}
.tpabout__inner p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--tp-common-white);
  margin-bottom: 65px;
}
@media (max-width: 767px) {
  .tpabout__inner p br {
    display: none;
  }
}
.tpabout__inner-btn a {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  padding: 16px 58px;
  border-radius: 3px;
  text-transform: uppercase;
}
.tpabout__inner-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.tpabout__inner-list ul li {
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-text-body);
}
.tpabout__inner-list ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  text-align: center;
  line-height: 25px;
}
.tpabout__inner-2 p {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .tpabout__inner-2 p br {
    display: none;
  }
}
.tpabout__inner-title-2 {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
}
.tpabout__inner-tag {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.tpabout__inner-tag .active {
  color: var(--tp-common-white);
  font-weight: 500;
  margin-right: 8px;
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  padding: 3px 16px;
  border-radius: 30px;
}
.tpabout__inner-thumb-2 > img {
  animation: left-right 3s infinite linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpabout__inner-thumb-2 > img {
    width: 420px;
  }
}
@media (max-width: 767px) {
  .tpabout__inner-thumb-2 > img {
    max-width: 100%;
  }
}
.tpabout__inner-thumb-shape-one {
  position: absolute;
  bottom: -120px;
  right: 95px;
  animation: left-right 2s infinite linear;
}
.tpabout__inner-thumb-shape-two {
  position: absolute;
  top: -55px;
  left: 0;
  animation: left-right 4s infinite linear;
}

.img-box__thumb img {
  width: 100%;
}
.img-box__title {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 20px;
  color: var(--tp-theme-1);
}
.img-box__content p {
  font-size: 15px;
  color: var(--tp-text-body);
}
@media only screen and (min-width: 576px) and (max-width: 767px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .img-box__content p br {
    display: none;
  }
}

/*----------------------------------------*/
/*  17. CHOOSE CSS
/*----------------------------------------*/
.tpchoose__item {
  background-color: var(--tp-common-white);
  padding: 20px;
  border-radius: 20px;
  background: #f1f1f1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpchoose__item {
    padding: 30px 20px 30px 21px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpchoose__item {
    padding: 35px 15px 30px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpchoose__item {
    padding: 50px 15px 45px 15px;
  }
}
.tpchoose__item:hover .tpchoose__details i {
  opacity: 1;
  visibility: visible;
  animation: shakenext 1s ease infinite;
}
.tpchoose__item:hover .tpchoose__icon img {
  animation: lara 1s infinite linear;
}
.tpchoose__title {
  font-size: 16px;
  color: var(--tp-heading-primary);
  font-weight: 700;
}
.tpchoose__content p {
  font-size: 16px;
}
.tpchoose__details {
  color: var(--tp-heading-secondary);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.tpchoose__details:hover {
  color: var(--tp-heading-secondary);
}
.tpchoose__details i {
  display: inline-block;
  margin-left: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
}
.tpchoose__bg {
  background-size: cover;
}

/*----------------------------------------*/
/*  18. BRAND-PRODUCT CSS
/*----------------------------------------*/
.tpbrandproduct__main {
  border: 1px solid #ebeff4;
  padding: 55px 30px 35px 30px;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpbrandproduct__main {
    padding: 15px 15px 7px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbrandproduct__main {
    padding: 30px 15px 15px 15px;
  }
}
@media (max-width: 767px) {
  .tpbrandproduct__main {
    margin-bottom: 20px;
  }
}
.tpbrandproduct__main-contetn p {
  font-size: 15px;
  color: var(--tp-text-3);
}
.tpbrandproduct__item {
  border: 1px solid #ebeff4;
  border-radius: 10px;
  align-items: center;
  padding: 22px 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpbrandproduct__item {
    padding: 10px 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpbrandproduct__item {
    padding: 22px 18px;
  }
}
.tpbrandproduct__title {
  margin-bottom: 15px;
  color: var(--tp-heading-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.tpbrandproduct__img {
  flex: 0 0 auto;
  margin-right: 18px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpbrandproduct__img {
    margin-right: 5px;
  }
}
.tpbrandproduct__img img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.tpbrandproduct__product-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-heading-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: 5px;
  line-height: 20px;
}
.tpbrandproduct__product-title a:hover {
  color: var(--tp-heading-secondary);
}
.tpbrandproduct__bage {
  top: 0;
  left: 0;
}

.brand-product {
  border-radius: 0 0 10px 10px;
}

.brand-product-title {
  font-size: 22px;
}

/*----------------------------------------*/
/*  19. TESTIMONIAL CSS
/*----------------------------------------*/
.tptestimonial__avata img {
  height: 70px;
  width: 70px;
  border-radius: 50px;
}
.tptestimonial__content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  .tptestimonial__content p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tptestimonial__content p br {
    display: none;
  }
}
.tptestimonial__rating {
  color: var(--tp-text-1);
}
.tptestimonial__rating a {
  margin: 0 -3px;
}
.tptestimonial__title {
  font-weight: 700;
  font-size: 13px;
  color: var(--tp-heading-primary);
  text-transform: uppercase;
  margin-bottom: 0;
}
.tptestimonial__content2 p {
  font-size: 16px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  (max-width: 767px) {
  .tptestimonial__content2 p br {
    display: none;
  }
}
.tptestimonial__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.tptestimonial__bg:hover .tptestimonial__prv,
.tptestimonial__bg:hover .tptestimonial__nxt {
  opacity: 1;
  visibility: visible;
}

.testimonial__shape img {
  position: absolute;
}
.testimonial__shape-one {
  top: -25px;
  left: 230px;
  animation: slider-anim 2s infinite linear alternate;
}
.testimonial__shape-two {
  top: -85px;
  right: 335px;
  animation: up-down 3s infinite linear alternate;
}
.testimonial__shape-three {
  bottom: -440px;
  left: 420px;
  animation: up-down 2.5s infinite linear alternate;
}

.testi-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
.testi-arrow i {
  height: 50px;
  width: 50px;
  background-color: var(--tp-heading-secondary);
  opacity: 0.3;
  line-height: 50px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--tp-common-white);
  display: inline-block;
}
.testi-arrow i:hover {
  opacity: 1;
}
.testi-arrow.tptestimonial-arrow-left {
  left: 250px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .testi-arrow.tptestimonial-arrow-left {
    left: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testi-arrow.tptestimonial-arrow-left {
    left: 15px;
  }
}
.testi-arrow.tptestimonial-arrow-right {
  right: 250px;
  left: 0 auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testi-arrow.tptestimonial-arrow-right {
    right: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testi-arrow.tptestimonial-arrow-right {
    right: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testi-arrow.tptestimonial-arrow-right {
    right: 15px;
  }
}

.tptestimonial__prv {
  left: 220px;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .tptestimonial__prv {
    left: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tptestimonial__prv {
    left: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tptestimonial__prv {
    left: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tptestimonial__prv {
    left: 100px;
  }
}
.tptestimonial__nxt {
  right: 220px;
  left: 0 auto;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .tptestimonial__nxt {
    right: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tptestimonial__nxt {
    right: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tptestimonial__nxt {
    right: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tptestimonial__nxt {
    right: 100px;
  }
}

.tptestimonial-active3 .tptestimonial__item {
  border: 1px solid #ebeff4;
  border-radius: 10px;
  padding: 40px 30px 35px 30px;
}

/*----------------------------------------*/
/*  20. SHOP CSS
/*----------------------------------------*/
.tpshop__leftbar {
  background-color: var(--tp-common-white);
  padding: 25px 30px 18px 30px;
  border-radius: 10px;
  width: 17vw;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpshop__leftbar {
    margin-right: 0;
  }
}
.tpshop__widget {
  border-bottom: dotted 1px #000;
}
.tpshop__widget:last-child {
  border-bottom: none;
}
.tpshop__widget-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}
.tpshop__widget .form-check-input[type="checkbox"] {
  border-radius: 3px;
  height: 15px;
  width: 15px;
  border: 1px solid var(--tp-border-3);
  
}
.tpshop__widget .form-check-input:focus {
  box-shadow: none;
}
.tpshop__widget .form-check-input:checked {
  background-color: var(--tp-heading-secondary);
  border-color: var(--tp-border-1);
}
.tpshop__widget label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-text-3);
}
.tpshop__widget .form-check i {
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.3em;
  color: #ffb800;
  transform: translateY(-2px);
}
.tpshop__widget-color-box .form-check-input[type="checkbox"] {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: none;
  
}
.tpshop__widget-color-box input {
  background-color: var(--tp-common-black);
}
.tpshop__widget-color-box .form-check-input:checked {
  background-color: var(--tp-common-black);
  border-color: var(--tp-border-1);
}
.tpshop__widget-color-box .form-check-input.blue-input {
  background-color: #1e73be;
}
.tpshop__widget-color-box .form-check-input.brown-input {
  background-color: #49271d;
}
.tpshop__widget-color-box .form-check-input.grey-input {
  background-color: #bfbfbf;
}
.tpshop__widget-color-box .form-check-input.green-input {
  background-color: #50b948;
}
.tpshop__widget-color-box .form-check-input.orange-input {
  background-color: #ff7900;
}
.tpshop__widget-color-box .form-check-input.red-input {
  background-color: #cb2028;
}
.tpshop__content span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: var(--tp-text-1);
  display: inline-block;
  margin-bottom: 8px;
}
.tpshop__content-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercas;
  color: var(--tp-common-white);
}
.tpshop__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-common-white);
  margin-bottom: 0;
}
.tpshop__banner {
  padding: 45px 0;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tpshop__banner {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpshop__top {
    margin-left: 0;
    margin-top: 30px;
  }
}
.tpshop__leftbar-area {
  margin-left: -60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpshop__leftbar-area {
    margin-left: 0;
  }
}
.tpshop__leftbar-area.tpshop__leftbar {
  margin-right: 0;
}
.tpshop__leftbar-area .tpshop__sidbar-thumb img {
  max-width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpshop__sidebar-area {
    margin-right: 0;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .product__navtabs {
    justify-content: start !important;
  }
}
.product__filter-content {
  background-color: var(--tp-common-white);
  border-radius: 10px;
  padding: 7px 20px;
}

@media (max-width: 767px) {
  .tpproductnav {
    justify-content: start !important;
  }
}
.tpproductnav .nav-tabs {
  border-bottom: none;
}
.tpproductnav .nav-tabs .nav-link.active {
  color: var(--tp-heading-secondary);
  background-color: transparent;
  border-color: none;
  border: none;
}
.tpproductnav .nav-tabs .nav-link.active i {
  color: var(--tp-heading-secondary);
}
.tpproductnav .nav-tabs .nav-link:focus,
.tpproductnav .nav-tabs .nav-link:hover {
  border: none;
}
.tpproductnav .nav-tabs .nav-link {
  border: none;
}
.tpproductnav .nav-tabs .nav-link i {
  color: #d6d6e5;
}

.tp-shop-selector .nice-select {
  border: none;
}
.tp-shop-selector .nice-select .list {
  border-radius: 0;
  box-shadow: none;
  border: none;
  width: 100%;
}

.tpproduct__shop-item {
  position: relative;
  z-index: 1;
}

.productsidebar__range .ui-slider-horizontal {
  height: 4px;
  background-color: #ebeff4;
  border-radius: 10px;
  border: none;
}
.productsidebar__range .ui-slider-horizontal .ui-slider-range {
  background: var(--tp-heading-secondary);
}
.productsidebar__range .ui-slider .ui-slider-handle {
  height: 14px;
  width: 14px;
  background-color: var(--tp-common-white);
}
.productsidebar__range .ui-state-default,
.productsidebar__range .ui-widget-content .ui-state-default,
.productsidebar__range .ui-widget-header .ui-state-default,
.productsidebar__range .ui-button,
.productsidebar__range html .ui-button.ui-state-disabled:hover,
.productsidebar__range html .ui-button.ui-state-disabled:active {
  border: 4px solid var(--tp-heading-secondary);
  border-radius: 50%;
}
.productsidebar__btn a {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  border-radius: 30px;
  display: block;
  padding: 10px 10px;
  text-align: center;
}

.price-filter input {
  outline: none;
  border: none;
  font-size: 14px;
  color: #4d5574;
  font-weight: 400;
  margin-top: 5px;
}

/*----------------------------------------*/
/*  21. CART CSS
/*----------------------------------------*/
.tpcartitem {
  position: relative;
}
.tpcartitem::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--tp-border-1);
  top: 0;
  right: -20px;
}
.tpcartitem__thumb {
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
}
.tpcartitem__thumb img {
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpcartitem__thumb img:hover {
  transform: scale(1.1);
}
.tpcartitem__content ul li {
  list-style: none;
  margin-bottom: 2px;
}
.tpcartitem__content ul li a {
  font-weight: 400;
  font-size: 15px;
  color: var(--tp-text-body);
  display: inline-block;
}
.tpcartitem__content ul li a:hover {
  color: var(--tp-heading-secondary);
}
.tpcartitem__title {
  font-weight: 700;
  font-size: 14px;
  color: var(--tp-heading-primary);
}
.tpcartitem__title a:hover {
  color: var(--tp-heading-secondary);
}
.tpcartitem__all {
  font-weight: 500;
  font-size: 15px;
  color: var(--tp-heading-primary);
  display: inline-block;
  margin-top: 10px;
}
.tpcartitem__all i {
  transform: translateY(1px);
  margin-left: -3px;
  display: inline-block;
}

.tpsubscribe__item {
  box-shadow: 0px 0px 50px rgba(31, 46, 168, 0.08);
  border-radius: 10px;
  background-color: var(--tp-common-white);
  padding: 40px 50px;
  margin-bottom: -65px;
}
@media (max-width: 767px) {
  .tpsubscribe__item {
    padding: 40px 15px;
    margin-bottom: 65px;
  }
}
.tpsubscribe__form {
  position: relative;
  margin-left: -20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpsubscribe__form {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .tpsubscribe__form {
    margin-left: 0px;
  }
}
.tpsubscribe__form input {
  width: 280px;
  height: 50px;
  border: 2px solid var(--tp-border-3);
  border-radius: 50px;
  padding: 5px 50px;
}
@media (max-width: 767px) {
  .tpsubscribe__form input {
    width: 100%;
  }
}
.tpsubscribe__form span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
}
@media (max-width: 767px) {
  .tpsubscribe__form span {
    top: 12px;
    transform: translateY(0%);
  }
}
.tpsubscribe__form-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: var(--tp-common-white);
  text-transform: uppercase;
  padding: 12px 30px;
  background-color: var(--tp-theme-1);
  border-radius: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpsubscribe__form-btn {
    right: 165px;
  }
}
@media (max-width: 767px) {
  .tpsubscribe__form-btn {
    position: static;
    margin-top: 40px;
  }
}
.tpsubscribe__form-btn:hover {
  background-color: var(--tp-heading-secondary);
}
.tpsubscribe__content p {
  color: var(--tp-theme-1);
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpsubscribe__content p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpsubscribe__content p br {
    display: none;
  }
}

.table-content table {
  background: var(--tp-common-white);
  border-color: var(--tp-border-1);
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .table-content table {
    width: 150%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .table-content table {
    width: 200%;
  }
}
@media (max-width: 767px) {
  .table-content table {
    width: 300%;
  }
}
.table-content table.table > :not(:first-child) {
  border-top: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--tp-border-1);
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: var(--tp-text-primary);
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid var(--tp-border-1);
  border-right: 1px solid var(--tp-border-1);
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid var(--tp-border-1);
}

.table td,
.table th {
  border-top: 1px solid var(--tp-border-1);
}

.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 52px;
  border: 1px solid var(--tp-border-1);
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid var(--tp-border-1);
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid var(--tp-border-1);
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

.product-quantity .cart-plus,
.product-quantity .cart-minus {
  width: 25px;
  height: 30px;
  border: 1px solid var(--tp-border-1);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
}
.product-quantity .cart-plus:hover,
.product-quantity .cart-minus:hover {
  cursor: pointer;
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
}

.cart-input {
  height: 30px;
  width: 32px;
  text-align: center;
  font-size: 14px;
  border: none;
  border-top: 2px solid var(--tp-border-1);
  border-bottom: 2px solid var(--tp-border-1);
  display: inline-block;
  vertical-align: middle;
  margin: 0 -3px;
  padding-bottom: 0px;
}

.tp-wish-cart {
  min-width: 150px;
}

/* Checkout */
.coupon-accordion h3 {
  background-color: #f6f6f6;
  border-top: 3px solid rgba(150, 174, 0, 0.3);
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover,
p.lost-password a:hover {
  color: var(--tp-theme-primary);
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}

.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus,
.coupon-info p.form-row-last input:focus {
  border-color: var(--tp-text-primary);
}

.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type="checkbox"] {
  position: relative;
  top: 4px;
  transform: translateY(-3px);
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input,
.coupon input[type="submit"],
.buttons-cart a,
.coupon-info p.form-row input[type="submit"] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type="text"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
p.checkout-coupon input[type="text"]:focus {
  border-color: var(--tp-text-primary);
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 24px;
  font-family: var(--tp-ff-jost);
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label,
.checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required,
.checkout-form-list label span.required {
  color: red;
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type="text"],
.checkout-form-list input[type="password"],
.checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.checkout-form-list input[type="text"]:focus,
.checkout-form-list input[type="password"]:focus,
.checkout-form-list input[type="email"]:focus {
  border-color: var(--tp-text-primary);
}

.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
  transform: translateY(-3px);
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
  font-family: var(--tp-ff-jost);
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 120px;
  padding: 15px;
  width: 100%;
  outline: 0;
  resize: none;
}

.order-notes textarea:focus {
  border-color: var(--tp-text-primary);
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid rgba(150, 174, 0, 0.3);
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  font-family: var(--tp-ff-jost);
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th,
.your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th,
  .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #f9f9f9;
}

.your-order-table table tr.order-total td span {
  color: var(--tp-theme-primary);
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid var(--tp-border-primary);
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-theme-primary);
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.payment-method .accordion-button:not(.collapsed) {
  color: var(--tp-theme-primary);
  background-color: var(--tp-common-white);
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

/*----------------------------------------*/
/*  22. COMING-SOON CSS
/*----------------------------------------*/
.tpsoon__sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--tp-heading-secondary);
}
.tpsoon__title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpsoon__title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpsoon__title br {
    display: none;
  }
}
.tpsoon p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
  margin-bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpsoon p br {
    display: none;
  }
}
.tpsoon__input {
  position: relative;
}
.tpsoon__input input {
  height: 50px;
  width: 330px;
  background-color: var(--tp-common-white);
  border: none;
  border-radius: 30px;
  padding: 5px 45px;
}
.tpsoon__input input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-border-2);
}
.tpsoon__input input:-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-border-2);
}
.tpsoon__input input::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-border-2);
}
.tpsoon__input input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tp-border-2);
}
@media (max-width: 767px) {
  .tpsoon__input input {
    width: 100%;
  }
}
.tpsoon__input span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.tpsoon__submit-wrapper {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpsoon__submit-wrapper {
    flex-wrap: wrap;
  }
}
.tpsoon__copyright a {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-heading-secondary);
}
.tpsoon__shape-one {
  position: absolute;
  top: 145px;
  left: 140px;
}
.tpsoon__shape-two {
  position: absolute;
  bottom: 240px;
  right: 35px;
}

.rainbow__social a i {
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #3c5b9b;
  border-radius: 50%;
  color: var(--tp-common-white);
  margin-right: 6px;
}
.rainbow__social .rainbow__facebook i {
  background-color: #40c1df;
}
.rainbow__social .rainbow__pin i {
  background-color: #eb281d;
}
.rainbow__social .rainbow__skype i {
  background-color: #21a7c6;
}
.rainbow__social .rainbow__youtube i {
  background-color: #ed3c32;
}

/*----------------------------------------*/
/*  23. SHOP-LOCATION CSS
/*----------------------------------------*/
.tplocation__thumb img {
  border-radius: 10px 10px 0 0;
}
.tplocation__content {
  background-color: #f7f7f9;
  border: 1px solid #ebeff4;
  border-radius: 0 0 10px 10px;
  padding: 65px 40px 50px 40px;
}
@media (max-width: 767px) {
  .tplocation__content {
    padding: 50px 15px 40px 15px;
  }
}
.tplocation__content ul li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
  margin-bottom: 2px;
}
@media (max-width: 767px) {
  .tplocation__content ul li {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .tplocation__content ul li br {
    display: none;
  }
}
.tplocation__content ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--tp-text-body);
}
@media (max-width: 767px) {
  .tplocation__content ul li a {
    font-size: 15px;
  }
}
.tplocation__content ul li .tplocation__button {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--tp-common-white) !important;
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  padding: 16px 47px;
  border-radius: 30px;
}
.tplocation__content ul li .tplocation__button:hover {
  background-color: #859a00;
}
.tplocation__content ul li span {
  color: var(--tp-heading-secondary);
  display: inline-block;
}
.tplocation__content-two {
  background-color: transparent;
  border: none;
  padding: 0;
}
.tplocation__text-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
.tplocation__wrapper {
  border: 1px solid #ebeff4;
  padding: 20px 30px 20px 20px;
  border-radius: 10px;
  height: 772px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--tp-heading-secondary) #e2e6eb;
}
@media (max-width: 767px) {
  .tplocation__wrapper {
    padding: 20px 15px 20px 15px;
  }
}
.tplocation__item {
  padding-bottom: 20px;
  border-bottom: 1px solid #e6eaf0;
  margin-bottom: 40px;
}
.tplocation__item:hover .tplocation__text-title {
  color: var(--tp-heading-secondary);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tplocation__item {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}
.tplocation__item:last-child {
  padding-bottom: 0;
  border: none;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .tplocation__img {
    margin-bottom: 20px;
  }
}
.tplocation__img img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tplocation__img img {
    width: 100%;
    margin-bottom: 20px;
  }
}

.tplocation__wrapper::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}

.tpcontactmap {
  height: 770px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcontactmap {
    height: 650px;
  }
}
@media (max-width: 767px) {
  .tpcontactmap {
    height: 350px;
  }
}
.tpcontactmap iframe {
  height: 100%;
  border-radius: 10px;
  width: 100%;
}

/*----------------------------------------*/
/*  24. CONTACT CSS
/*----------------------------------------*/
.tpcontact-inner-sub-title {
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  padding: 5px 15px;
  border-radius: 30px;
}
.tpcontact-inner-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .tpcontact-inner-title {
    font-size: 28px;
  }
}
.tpcontact-inner-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
  font-family: var(--tp-ff-jost);
}
.tpcontact__img img {
  border-radius: 10px;
}

.tpmap__box {
  position: relative;
}
.tpmap__wrapper {
  position: absolute;
  bottom: -25px;
  left: 0;
  top: 0;
  width: 50%;
  z-index: -1;
}
@media (max-width: 767px) {
  .tpmap__wrapper {
    position: static;
    width: 100%;
    height: 400px;
    margin-bottom: 75px;
  }
}
.tpmap__wrapper iframe {
  height: 100%;
  width: 100%;
}

.tpform__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .tpform__wrapper {
    padding-top: 0;
    margin-left: 0;
  }
}
.tpform__wrapper p {
  font-family: var(--tp-ff-jost);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
  margin-bottom: 25px;
}
.tpform__input input {
  width: 100%;
  height: 50px;
  border: 1px solid #ebeff4;
  border-radius: 30px;
  padding: 5px 20px;
}
.tpform__input input::placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpform__input input:focus {
  border: 1px solid var(--tp-heading-primary);
}
.tpform__textarea textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  border: 1px solid #ebeff4;
  border-radius: 20px;
  resize: none;
  padding: 5px 20px;
}
.tpform__textarea textarea::placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tpform__textarea textarea:focus {
  border: 1px solid var(--tp-heading-primary);
  outline: 0;
}
.tpform__textarea button {
  font-family: var(--tp-ff-jost);
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  display: inline-block;
  padding: 16px 50px;
  border-radius: 30px;
}
.tpform__textarea-check .form-check-input:checked {
  background-color: var(--tp-heading-secondary);
  border-color: transparent;
}
.tpform__textarea-check .form-check-input:focus {
  border: 1px solid #ebeff4;
  box-shadow: none;
  cursor: pointer;
}
.tpform__textarea-check .form-check-label {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--tp-text-body);
}
.tpform__textarea-check .form-check-label a {
  color: var(--tp-heading-secondary);
  text-decoration: underline;
}

/*----------------------------------------*/
/*  25. LOG-IN CSS
/*----------------------------------------*/
.tptrack__item-icon {
  flex: 0 0 auto;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background-color: var(--tp-common-white);
  border-radius: 6px;
  margin-right: 20px;
}
.tptrack__content {
  padding: 50px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tptrack__content {
    padding: 20px 15px;
  }
}
.tptrack__item-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-heading-primary);
}
.tptrack__item-content p {
  font-size: 14px;
  color: var(--tp-text-body);
  line-height: 24px;
  margin-bottom: 0;
}
.tptrack__id span,
.tptrack__email span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  font-size: 16px;
}
.tptrack__id form,
.tptrack__email form {
  position: relative;
}
.tptrack__id form input,
.tptrack__email form input {
  width: 100%;
  height: 60px;
  border: none;
  background-color: var(--tp-common-white);
  padding: 10px 60px;
  border-radius: 6px;
}
.tptrack__id form input::-webkit-input-placeholder,
.tptrack__email form input::-webkit-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tptrack__id form input:-moz-placeholder,
.tptrack__email form input:-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tptrack__id form input::-moz-placeholder,
.tptrack__email form input::-moz-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tptrack__id form input:-ms-input-placeholder,
.tptrack__email form input:-ms-input-placeholder {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94ac;
}
.tptrack__submition {
  background: var(--tp-heading-primary);
  border-radius: 6px;
  color: var(--tp-common-white);
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  padding: 22px 50px;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}
.tptrack__submition i {
  margin-left: 20px;
}
.tptrack__submition:hover,
.tptrack__submition.active {
  background-color: var(--tp-heading-secondary);
}

.tptrack__submition.active:hover {
  background-color: #859a00;
}

/*----------------------------------------*/
/*  26. FAQ CSS
/*----------------------------------------*/
.tpfaq__title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.03em;
  color: #2d2a6e;
}
.tpfaq__content {
  border-bottom: 1px solid var(--tp-border-1);
  margin-bottom: 60px;
  padding-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tpfaq__content {
    margin-right: 0;
  }
}
.tpfaq__content p,
.tpfaq__content span {
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #4d5574;
}
.tpfaq__content span {
  display: block;
}
.tpfaq__content ul {
  margin-bottom: 20px;
}
.tpfaq__content ul li {
  list-style: none;
  margin-bottom: 20px;
}
.tpfaq__item .accordion-button:not(.collapsed) {
  color: var(--tp-heading-secondary);
}
.tpfaq__item .accordion-button {
  color: var(--tp-text-body);
  font-family: var(--tp-ff-jost);
  background-color: transparent;
  box-shadow: none;
  font-weight: 500;
  font-size: 18px;
  padding: 20px 0 20px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpfaq__item .accordion-button {
    font-size: 17px;
  }
}
.tpfaq__item .accordion-button::after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  background-image: none;
}
.tpfaq__item .accordion {
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
}
.tpfaq__item .accordion-item {
  color: #b52727;
  border-bottom: 1px solid #e6ecf0;
  font-family: var(--tp-ff-jost);
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-text-body);
}
.tpfaq__item .accordion-body {
  padding: 0 30px 30px 0;
}
.tpfaq__item .accordion-button:not(.collapsed)::after {
  background-image: none;
}

/*----------------------------------------*/
/*  27. FOOTER CSS
/*----------------------------------------*/
.tpfooter__top {
  border-bottom: 1px solid #484492;
}
.tpfooter__widget-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: var(--tp-common-white);
  margin-bottom: 30px;
}
.tpfooter__widget p {
  color: var(--tp-grey-10);
  font-size: 16px;
  margin-bottom: 0;
}
.tpfooter__widget p a {
  color: var(--tp-heading-secondary);
}
.tpfooter__widget-social a {
  color: var(--tp-common-white);
  font-size: 16px;
  margin-right: 5px;
}
.tpfooter__widget-social a:hover {
  color: var(--tp-heading-secondary);
}
.tpfooter__widget-social-title {
  color: var(--tp-grey-10);
  display: block;
}
.tpfooter__widget-social img {
  width: 100%;
}
.tpfooter__widget-time-info span {
  font-size: 16px;
  color: var(--tp-grey-10);
  display: block;
}
.tpfooter__widget-time-info span b {
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpfooter__widget-time-info span b {
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfooter__widget-time-info span b {
    font-size: 13px;
  }
}
.tpfooter__widget-links ul li {
  list-style: none;
}
.tpfooter__widget-links ul li a {
  font-size: 16px;
  color: var(--tp-grey-10)!important;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: inline-block;
  margin-bottom: 8px;
}
.tpfooter__widget-links ul li a:hover {
  color: var(--tp-common-white);
  padding-left: 8px;
}
.tpfooter__widget-newsletter p {
  font-size: 16px;
  color: var(--tp-common-white);
  margin-bottom: 20px;
}
.col-xl-4 {
  .tpproduct {
    /* min-height: 500px; */
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfooter__widget-newsletter p br {
    display: none;
  }
}
.tpfooter__widget-newsletter form {
  position: relative;
}
.tpfooter__widget-newsletter form input {
  border-radius: 3px;
  height: 50px;
  width: 100%;
  background-color: var(--tp-common-white);
  border: none;
  padding: 5px 150px 5px 50px;
}
.tpfooter__widget-newsletter form input::placeholder {
  color: #acafb7;
  font-size: 14px;
}
.tpfooter__widget-newsletter form span {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.tpfooter__widget-newsletter-submit {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.tpfooter__widget-newsletter-check .form-check-label {
  font-size: 14px;
  color: var(--tp-common-white);
}
.tpfooter__widget-newsletter-check .form-check-input:checked {
  background-color: var(--tp-heading-secondary);
  border-color: transparent;
}
.tpfooter__widget-newsletter-check .form-check-input:focus {
  box-shadow: none;
}
.tpfooter__widget-newsletter-check
  .form-check-input.form-check-input[type="checkbox"] {
  border-radius: 2px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfooter__copyright {
    text-align: center;
  }
  .tpfooter__developer {
    text-align: center;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfooter__copyright {
    margin-bottom: 20px;
  }
  .tpfooter__developer {
    margin-bottom: 20px;
  }
}
.tpfooter__copyright-text {
  font-size: 16px;
  color: var(--tp-grey-10);
}
.tpfooter__copyright-text a {
  color: var(--tp-heading-secondary);
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .tpfooter__copyright-thumb {
    text-align: center !important;
  }
}
.tpfooter__copyright-thumb img {
  max-width: 100%;
}
.tpfooter__phone-num {
  font-weight: 500;
  font-size: 30px;
  line-height: 43px;
  color: var(--tp-heading-secondary);
  line-height: 1;
}
.tpfooter__phone-num:hover {
  color: var(--tp-common-white);
}
.tpfooter__border {
  border-top: 3px solid var(--tp-heading-secondary);
}

.footer-col-2 {
  margin-left: -40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-col-2 {
    margin-left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-col-2 {
    margin-left: 0px;
  }
}

.footer-col-3 {
  margin-left: -100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-col-3 {
    margin-left: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-col-3 {
    margin-left: 0px;
  }
}

.footer-col-4 {
  margin-left: 60px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-col-4 {
    margin-left: 0px;
  }
}
.footer-col-4::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: #484492;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-col-4::before {
    display: none;
  }
}

.footer-border {
  border-top: 2px solid var(--tp-heading-secondary);
}

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-115 {
  margin-top: 115px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-125 {
  margin-top: 125px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-145 {
  margin-top: 145px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-155 {
  margin-top: 155px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-165 {
  margin-top: 165px;
}
.mt-170 {
  margin-top: 170px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-180 {
  margin-top: 180px;
}
.mt-185 {
  margin-top: 185px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-195 {
  margin-top: 195px;
}
.mt-200 {
  margin-top: 200px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-105 {
  margin-bottom: 105px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mb-115 {
  margin-bottom: 115px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-125 {
  margin-bottom: 125px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-135 {
  margin-bottom: 135px;
}
.mb-140 {
  margin-bottom: 140px;
}
.mb-145 {
  margin-bottom: 145px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-155 {
  margin-bottom: 155px;
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-175 {
  margin-bottom: 175px;
}
.mb-180 {
  margin-bottom: 180px;
}
.mb-185 {
  margin-bottom: 185px;
}
.mb-190 {
  margin-bottom: 190px;
}
.mb-195 {
  margin-bottom: 195px;
}
.mb-200 {
  margin-bottom: 200px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-105 {
  margin-left: 105px;
}
.ml-110 {
  margin-left: 110px;
}
.ml-115 {
  margin-left: 115px;
}
.ml-120 {
  margin-left: 120px;
}
.ml-125 {
  margin-left: 125px;
}
.ml-130 {
  margin-left: 130px;
}
.ml-135 {
  margin-left: 135px;
}
.ml-140 {
  margin-left: 140px;
}
.ml-145 {
  margin-left: 145px;
}
.ml-150 {
  margin-left: 150px;
}
.ml-155 {
  margin-left: 155px;
}
.ml-160 {
  margin-left: 160px;
}
.ml-165 {
  margin-left: 165px;
}
.ml-170 {
  margin-left: 170px;
}
.ml-175 {
  margin-left: 175px;
}
.ml-180 {
  margin-left: 180px;
}
.ml-185 {
  margin-left: 185px;
}
.ml-190 {
  margin-left: 190px;
}
.ml-195 {
  margin-left: 195px;
}
.ml-200 {
  margin-left: 200px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-105 {
  margin-right: 105px;
}
.mr-110 {
  margin-right: 110px;
}
.mr-115 {
  margin-right: 115px;
}
.mr-120 {
  margin-right: 120px;
}
.mr-125 {
  margin-right: 125px;
}
.mr-130 {
  margin-right: 130px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-140 {
  margin-right: 140px;
}
.mr-145 {
  margin-right: 145px;
}
.mr-150 {
  margin-right: 150px;
}
.mr-155 {
  margin-right: 155px;
}
.mr-160 {
  margin-right: 160px;
}
.mr-165 {
  margin-right: 165px;
}
.mr-170 {
  margin-right: 170px;
}
.mr-175 {
  margin-right: 175px;
}
.mr-180 {
  margin-right: 180px;
}
.mr-185 {
  margin-right: 185px;
}
.mr-190 {
  margin-right: 190px;
}
.mr-195 {
  margin-right: 195px;
}
.mr-200 {
  margin-right: 200px;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-110 {
  padding-top: 110px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-125 {
  padding-top: 125px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-135 {
  padding-top: 135px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-145 {
  padding-top: 145px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-155 {
  padding-top: 155px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-165 {
  padding-top: 165px;
}
.pt-170 {
  padding-top: 170px;
}
.pt-175 {
  padding-top: 175px;
}
.pt-180 {
  padding-top: 180px;
}
.pt-185 {
  padding-top: 185px;
}
.pt-190 {
  padding-top: 190px;
}
.pt-195 {
  padding-top: 195px;
}
.pt-200 {
  padding-top: 200px;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-115 {
  padding-bottom: 115px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-125 {
  padding-bottom: 125px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pb-135 {
  padding-bottom: 135px;
}
.pb-140 {
  padding-bottom: 140px;
}
.pb-145 {
  padding-bottom: 145px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-155 {
  padding-bottom: 155px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-165 {
  padding-bottom: 165px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-175 {
  padding-bottom: 175px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-185 {
  padding-bottom: 185px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-195 {
  padding-bottom: 195px;
}
.pb-200 {
  padding-bottom: 200px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-105 {
  padding-left: 105px;
}
.pl-110 {
  padding-left: 110px;
}
.pl-115 {
  padding-left: 115px;
}
.pl-120 {
  padding-left: 120px;
}
.pl-125 {
  padding-left: 125px;
}
.pl-130 {
  padding-left: 130px;
}
.pl-135 {
  padding-left: 135px;
}
.pl-140 {
  padding-left: 140px;
}
.pl-145 {
  padding-left: 145px;
}
.pl-150 {
  padding-left: 150px;
}
.pl-155 {
  padding-left: 155px;
}
.pl-160 {
  padding-left: 160px;
}
.pl-165 {
  padding-left: 165px;
}
.pl-170 {
  padding-left: 170px;
}
.pl-175 {
  padding-left: 175px;
}
.pl-180 {
  padding-left: 180px;
}
.pl-185 {
  padding-left: 185px;
}
.pl-190 {
  padding-left: 190px;
}
.pl-195 {
  padding-left: 195px;
}
.pl-200 {
  padding-left: 200px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-105 {
  padding-right: 105px;
}
.pr-110 {
  padding-right: 110px;
}
.pr-115 {
  padding-right: 115px;
}
.pr-120 {
  padding-right: 120px;
}
.pr-125 {
  padding-right: 125px;
}
.pr-130 {
  padding-right: 130px;
}
.pr-135 {
  padding-right: 135px;
}
.pr-140 {
  padding-right: 140px;
}
.pr-145 {
  padding-right: 145px;
}
.pr-150 {
  padding-right: 150px;
}
.pr-155 {
  padding-right: 155px;
}
.pr-160 {
  padding-right: 160px;
}
.pr-165 {
  padding-right: 165px;
}
.pr-170 {
  padding-right: 170px;
}
.pr-175 {
  padding-right: 175px;
}
.pr-180 {
  padding-right: 180px;
}
.pr-185 {
  padding-right: 185px;
}
.pr-190 {
  padding-right: 190px;
}
.pr-195 {
  padding-right: 195px;
}
.pr-200 {
  padding-right: 200px;
}

a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #070337;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #070337;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 10px 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-bottom: 1px #4d49a1 dotted;
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 10px 10%;
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 10px 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 10px 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 10px 25%;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
  font-size: 14px;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

/*--- preloader ---*/

.dark #preloader {
  background-color: #232323;
}

#preloader {
  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  bottom: 0;

  background-color: #f7f7f7;

  z-index: 999999;
}

.preloader {
  width: 50px;

  height: 50px;

  display: inline-block;

  padding: 0px;

  text-align: left;

  box-sizing: border-box;

  position: absolute;

  top: 50%;

  left: 50%;

  margin-left: -25px;

  margin-top: -25px;
}

.preloader span {
  position: absolute;

  display: inline-block;

  width: 50px;

  height: 50px;

  border-radius: 100%;

  background: #fb4f53;

  -webkit-animation: preloader 1.3s linear infinite;

  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.8s;

  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0.7, 0.7);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.7, 0.7);
  }
}

@-webkit-keyframes preloader {
  0% {
    transform: scale(0.7, 0.7);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.7, 0.7);
  }
}

/* Custom */

.header__top {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #d7641b;
}
.logo {
  width: 100px;
}
.category-area {
  padding-bottom: 25px!important;
}
.container__slider {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
  height: 400px;
}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: whitesmoke;
  font-size: 24px;
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 0;
}

.slider__btn-prev {
  position: absolute;
  left: 0;
}

.container__slider__links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.container__slider__links-small {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  outline: none;
  border: 0;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 1s ease;
  margin: 0 2px;
}

.container__slider__links-small-active {
  background-color: black;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  height: auto;
  transform: translateX(0);
  transition: transform 1s ease;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5 {
  transform: translateX(-400%);
}

.slider__item-active-6 {
  transform: translateX(-500%);
}

.slider__item-active-7 {
  transform: translateX(-600%);
}
.slider__item-active-8 {
  transform: translateX(-700%);
}
.slider__item-active-9 {
  transform: translateX(-800%);
}
.slider__item-active-10 {
  transform: translateX(-900%);
}

.slider__item img {
  width: 100%;
  height: auto;
}
.mobile {
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  background: #fff;
  position: fixed;
  top: 100px;
}
.cat {
  padding-top: 70px;
  padding-bottom: 50px;
}
.product-coundown-area {
  background: url(assets/img/banner/green-baner.png);
  background-repeat: no-repeat;
  background-size: auto;
}
.grey-bg {
  padding-bottom: 70px;
}
.m-menu a {
  font-size: 16px;
  color: #fff;
}
a {
  cursor: pointer;
}

/* Custom Media Queries */
@media only screen and (max-width: 600px) {
  .container__slider {
    height: auto;
  }
  .ml-175 {
    margin-left: 0;
  }
  
  .cart-container {
    padding: 2rem 1rem!important;
}
.btn-xs {
  padding: 5px 5px;
  width: auto!important;
    height: auto!important;
    font-size: 12px!important;
}
tbody, td, tfoot, th, thead, tr {
  padding: 10px;
}
.m-menu {
  margin-bottom: 15px;
}
}
table {
  width:100%;
}
/* Not Found */
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(68, 68, 68);
  min-height: 80vh;
}
.not-found h2 {
  font-size: 55px;
}
.not-found p {
  font-size: 20px;
}

.validationText {
  font-size: 14px;
    color: red;
}

.cart-container {
  padding: 2rem 4rem;
}
.cart-container h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
}
.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: table-row;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
 
  width: 200px;
  text-align: center;
}
.cart-item .cart-product img {
  width: auto;
  max-width: 100%;
  margin-right: 1rem;
  height: 100px;
  margin-top: 5px;
  margin: 0 auto;
}
.cart-item .cart-product h3 {
  font-weight: 400;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.norm {
  font-weight: 400;
}
.p-20 {
  padding: 20px;
  text-align: center;
}
.p-image {
  max-height: 200px;
  margin: 0 auto;
}
.tpproduct2 {
  min-height: 350px!important;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
  margin-top: 20px;;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}

/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary .clear-btn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout .cart-login {
  background-color: yellow;
  color: black;
}

.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swiper {
  width:100%;
}
.swiper-wrapper {
  justify-content: center;
}


.profile .profile-header .profile-cover-photo {
  width: 100%;
  height: 240px;
  border-radius: 8px;
}

.profile .profile-header .profile-author {
  padding: 32px;
  padding-top: 0;
  position: relative;
  z-index: 9;
  margin-top: -80px;
}

.profile .profile-header .profile-author .profile-name .name {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000;
}

.profile .profile-header .profile-author .profile-name .email {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-top: 8px;
}

.profile .profile-header .profile-author .profile-photo img {
  width: 160px;
  border-radius: 50%;
}

.profile .profile-title {
  position: relative;
  background-color: #F2F2F2;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
}

.profile .profile-title .title {
  line-height: 48px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.profile .profile-title .profile-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #542DED;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.profile .profile-details .single-details-item {
  padding: 8px 16px 0;
}

.profile .profile-details .single-details-item .details-title {
  width: 145px;
  margin-top: 8px;
}

@media (max-width: 767px) {
  .profile .profile-details .single-details-item .details-title {
    width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .profile .profile-details .single-details-item .details-title {
    width: 145px;
  }
}

.profile .profile-details .single-details-item .details-title .title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #4C4C4C;
}

.profile .profile-details .single-details-item .details-content {
  margin-top: 8px;
}

.profile .profile-details .single-details-item .details-content p {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 400;
}

.profile .single-card-info {
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
  border-radius: 8px;
  align-items: center;
}

.profile .single-card-info .card-icon img {
  border-radius: 4px;
}

.profile .single-card-info .card-info {
  padding-left: 16px;
}

.profile .single-card-info .card-info .card-name {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #000;
}

.profile .single-card-info .card-info .card-number {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #4C4C4C;
  margin-top: 5px;
}

.profile .single-card-info .card-info .card-number span {
  margin-left: 8px;
}

.sticky {
  position: fixed;
  top: 130px;
  width: 100%;
  /* Add any other styles for the sticky state */
}
.sticky2 {
  position: fixed;
  top: 105px;
  width: 100%;
  border: 1px solid #f1f1f1;
  background: #f1f1f1!important;
  z-index: 9999999;
  border-radius: 10px;
  /* Add any other styles for the sticky state */
}
.MuiPagination-root ul {
  justify-content: center;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #96ae00!important;
  color:#fff;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background: #96ae00!important;
}
.form .fa-search{

  position: relative;
  top: 30px;
  left: 10px;
  color: #9ca3af;
  z-index: 9999;

}
.form .form-input {
  padding-left: 30px;
}
.form span{

      position: relative;
  right: 17px;
  top: 15px;
  padding: 2px;
  

}

.left-pan{
  padding-left: 7px;
}

.left-pan i{
 
 padding-left: 10px;
}

.tpshop__widget {
  max-height: 400px;
  overflow: auto;
}
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 43%)!important;
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.tpproduct__price {
 position: absolute;
 bottom: 70px;
}
.edit-buttons {
  margin-top: -2px;
}
.edit-buttons .btn {
  margin-right: 5px;
}
.mt-5 {
margin-top:5px;
}
.category__thumb::after {
  display: none;
}
.category__thumb::before {
  display: none;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 23px)!important;
  left: auto;
}
.f14 {
  font-size:14px;
}
.f25 {
  font-size: 30px;
  font-weight: bold;
}
.blck {
  color:#000;
}

@media only screen and (min-width: 1000px) {
  .tpproduct {
  min-height: 400px;
  }
  .tpproduct .tp-btn-2 {
    position: absolute;
    bottom: 15px;
  }
  }

@media only screen and (max-width: 1000px) {
  .tpproduct__price {
    position: relative;
    bottom: 0px;
   }
.tpshop__leftbar {
  background-color: var(--tp-common-white);
  padding: 25px 30px 18px 30px;
  border-radius: 10px;
  width: 100%;
}
.sticky {
  position: relative;
  top: 0px;
  width: 100%;
  /* Add any other styles for the sticky state */
}
.sticky2 {
  position: relative;
  z-index: 9;
  top:0;
}
.your-component  {
  width:85vw!important;
}
.tpshop__leftbar {
  margin-bottom: 20px;
  width:90vw;
}
.header-sticky-2 {
  position: relative!important;
}
.header__info a i, .header__info button i {
  display: flex;
}
}


@media only screen and (max-width: 600px) {
  .swiper-wrapper {
    justify-content: left;
  }
  
}